import Vue from 'vue';
import VueRouter from 'vue-router';
import _ from 'lodash';
import Role from '../constants/role';
import routeLogic from '../logic/routeLogic';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: 'Login',
  },
  {
    path: '/login/',
    name: 'Login',
    component: () => import('../views/LoginView.vue'),
    meta: { isLoginRequired: false },
    props: true,
  },
  {
    path: '/gate/',
    name: 'Gate',
    component: () => import('../views/GateView.vue'),
    meta: { validRoles: [Role.INTERNAL], isLoginRequired: true },
    props: true,
  },
  {
    path: '/log/',
    name: 'EquipmentLogView',
    component: () => import('../views/EquipmentLogView.vue'),
    meta: { validRoles: [Role.INTERNAL], isLoginRequired: true },
    props: true,
  },
  {
    path: '/logSearch/',
    name: 'EquipmentLogSearchView',
    component: () => import('../views/EquipmentLogSearchView.vue'),
    meta: { validRoles: [Role.INTERNAL], isLoginRequired: true },
    props: true,
  },
  {
    path: '/yard/',
    name: 'YardView',
    component: () => import('../views/YardView.vue'),
    meta: { validRoles: [Role.INTERNAL], isLoginRequired: true },
    props: true,
  },
  {
    path: '/check/',
    name: 'YardCheckView',
    component: () => import('../views/YardCheckView.vue'),
    meta: { validRoles: [Role.INTERNAL], isLoginRequired: true },
    props: true,
  },
  {
    path: '/check/history',
    name: 'YardCheckHistoryView',
    component: () => import('../views/YardCheckHistoryView.vue'),
    meta: { validRoles: [Role.ADMIN], isLoginRequired: true },
    props: true,
  },
  {
    path: '/appointment/',
    name: 'AppointmentView',
    component: () => import('../views/AppointmentView.vue'),
    meta: { validRoles: [Role.ADMIN, Role.INTERNAL], isLoginRequired: true },
    props: true,
  },
  {
    path: '/jockey/',
    name: 'JockeyView',
    component: () => import('../views/JockeyView.vue'),
    meta: { validRoles: [Role.INTERNAL], isLoginRequired: true },
    props: true,
  },
  {
    path: '/passwordResetRequest',
    name: 'PasswordResetRequest',
    component: () => import('../views/PasswordResetRequestView.vue'),
    meta: { isLoginRequired: false },
    props: true,
  },
  {
    path: '/passwordReset',
    alias: '/passwordReset/:token',
    name: 'PasswordReset',
    component: () => import('../views/PasswordResetView.vue'),
    meta: { isLoginRequired: false },
    props: true,
  },
  {
    path: '/admin',
    name: 'Admin',
    meta: { validRoles: [Role.ADMIN], isLoginRequired: true },
    props: true,
  },
  {
    path: '/manageUsers',
    name: 'ManageUsersView',
    component: () => import('../views/ManageUsersView.vue'),
    meta: { validRoles: [Role.ADMIN], isLoginRequired: true },
    props: true,
  },
  {
    path: '/yardAccuracyReport',
    name: 'YardAccuracyReportView',
    component: () => import('../views/YardAccuracyReportView.vue'),
    meta: { validRoles: [Role.ADMIN], isLoginRequired: true },
    props: true,
  },
  {
    path: '/developer',
    name: 'Developer',
    meta: { validRoles: [Role.DEV], isLoginRequired: true },
    props: true,
  },
  {
    path: '/manageYards',
    name: 'ManageYardsView',
    component: () => import('../views/ManageYardsView.vue'),
    meta: { validRoles: [Role.DEV], isLoginRequired: true },
    props: true,
  },
  {
    path: '/overview',
    name: 'OverviewView',
    component: () => import('../views/OverviewView.vue'),
    meta: { validRoles: [Role.INTERNAL], isLoginRequired: true },
    props: true,
  },
  {
    path: '*',
    redirect: '/',
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const routesRequiringLogin = _.filter(routes, (route) => route.meta && route.meta.isLoginRequired);
  const routeNames = _.map(routesRequiringLogin, 'name');
  const loginRequired = routeNames.includes(to.name);
  const user = JSON.parse(localStorage.getItem('user'));
  const navigationMeta = to.meta;

  if (loginRequired && !user) {
    return next('/login');
  }

  // check if route is restricted by role
  if (navigationMeta && navigationMeta.validRoles) {
    const hasAccess = routeLogic.hasAccess(user.roles, navigationMeta.validRoles);
    if (!hasAccess) {
      return next(from.path);
    }
  }

  return next();
});

export default router;

<template>
    <v-row class="justify-center mb-5" v-if="alert.message">
      <v-col cols="4">
        <v-card :color="alert.color">
          <v-card-title class="text-h5 justify-center"> {{ alert.type }} </v-card-title>

          <v-card-text class="text-center" style="font-size: 16px;">
            {{ alert.message }}
          </v-card-text>

          <v-card-actions class="justify-center">
            <v-btn id="dismiss" @click="dismiss" text> Dismiss </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data: () => ({
  }),
  computed: {
    ...mapGetters('alert', ['alerts']),
    alert() {
      return this.alerts[0] || {};
    },
  },
  watch: {
    alerts() {
    },
  },
  methods: {
    ...mapActions('alert', ['clearFirst']),
    dismiss() {
      this.clearFirst();
    },
  },
};
</script>

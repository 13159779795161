function formatTime(time) {
  if (!time) {
    return null;
  }
  const timeDate = new Date();
  timeDate.setHours(time.hour);
  timeDate.setMinutes(time.minute);
  return timeDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
}

function mapDefault() {
  const yard = {
    name: '',
    yardId: '',
    groups: [{
      name: '',
      rangeStart: '',
      rangeEnd: '',
      type: '',
    }],
    appointmentStartTime: '',
    appointmentEndTime: '',
    maxAppointmentOverlap: null,
  };

  return yard;
}

function mapToApiYard(yardToMap) {
  if (yardToMap.yard.appointmentStartTime !== '' && yardToMap.yard.appointmentEndTime !== '') {
    const apiYard = {
      name: yardToMap.yard.name,
      groups: yardToMap.yard.groups,
      appointmentStartTime: {
        hour: parseInt(yardToMap.yard.appointmentStartTime.split(':')[0], 10),
        minute: parseInt(yardToMap.yard.appointmentStartTime.split(':')[1], 10),
      },
      maxAppointmentOverlap: parseInt(yardToMap.yard.maxAppointmentOverlap, 10),
      appointmentEndTime: {
        hour: parseInt(yardToMap.yard.appointmentEndTime.split(':')[0], 10),
        minute: parseInt(yardToMap.yard.appointmentEndTime.split(':')[1], 10),
      },
      yardId: yardToMap.yard.yardId,
    };

    return apiYard;
  }
  const apiYard = {
    name: yardToMap.yard.name,
    groups: yardToMap.yard.groups,
    maxAppointmentOverlap: parseInt(yardToMap.yard.maxAppointmentOverlap, 10),
    yardId: yardToMap.yard.yardId,
  };

  return apiYard;
}

function mapFromApiYard(apiYard) {
  const yard = {
    name: apiYard.name,
    yardId: apiYard.yardId,
    groups: apiYard.groups,
    appointmentStartTime: apiYard.appointmentStartTime,
    formattedStartTime: formatTime(apiYard.appointmentStartTime),
    maxAppointmentOverlap: apiYard.maxAppointmentOverlap,
    appointmentEndTime: apiYard.appointmentEndTime,
    formattedEndTime: formatTime(apiYard.appointmentEndTime),
  };

  return yard;
}

function mapFromApiYards(apiYards) {
  const yards = [];
  if (apiYards) {
    apiYards.forEach((apiYard) => {
      const yard = this.mapFromApiYard(apiYard);
      yards.push(yard);
    });
  }

  return yards;
}

export default {
  mapDefault,
  mapToApiYard,
  mapFromApiYard,
  mapFromApiYards,
};

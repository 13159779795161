import gateEntryMapper from '../mappers/gateEntryMapper';
import gateService from '../services/gateService';
import direction from '../constants/direction';

const getDefaultState = () => ({
  gateEntry: gateEntryMapper.getDefaultGateEntry(),
  isLoading: false,
});

const state = getDefaultState();

const getters = {
  gateEntry(state) {
    return state.gateEntry;
  },
  isLoading(state) {
    return state.isLoading;
  },
};

const actions = {
  async saveEntry({
    dispatch, commit, rootGetters,
  }, gateEntry) {
    commit('setIsLoading', true);
    gateEntry.enteredBy = rootGetters['account/fullName'];
    if (gateEntry.direction === direction.IN) {
      try {
        const locationEquipment = await gateService.processGateIn(gateEntry);
        if (locationEquipment?.locationID) {
          dispatch('location/addEquipmentToLocation', locationEquipment, { root: true });
        }
      } catch (error) {
        dispatch('alert/raiseError', error.message, { root: true });
      }
    } else if (gateEntry.direction === direction.OUT) {
      try {
        const result = await gateService.processGateOut(gateEntry);
        if (result !== null) {
          for (let i = 0; i < result.lostEquipmentList.length; i += 1) {
            dispatch('check/removedLostEquipment', result.lostEquipmentList[i], { root: true });
          }

          dispatch('location/removeEquipmentFromLocation', result.removedLocationEquipment, { root: true });

          for (let i = 0; i < result.pendingMoves.length; i += 1) {
            dispatch('move/removedPendingMove', result.pendingMoves[i], { root: true });
          }
        }
      } catch (error) {
        dispatch('alert/raiseError', error.message, { root: true });
      }
    }
    dispatch('location/setShowEditEquipmentDialog', false, { root: true });
    commit('setIsLoading', false);
  },
  setGateEntry({ commit }, gateEntry) {
    commit('setGateEntry', gateEntry);
  },
  resetState({ commit }) {
    commit('resetState');
  },
  setDirection({ commit }, direction) {
    commit('setDirection', direction);
  },
  setEquipmentId({ commit }, id) {
    commit('setEquipmentId', id);
  },
  setEquipmentSize({ commit }, size) {
    commit('setEquipmentSize', size);
  },
  setEquipmentState({ commit }, state) {
    commit('setEquipmentState', state);
  },
  setEquipmentNumber({ commit }, number) {
    commit('setEquipmentNumber', number);
  },
  setEquipmentType({ commit }, type) {
    commit('setEquipmentType', type);
  },
  setEquipment({ commit }, equipment) {
    commit('setEquipment', equipment);
  },
  setCarrierName({ commit }, name) {
    commit('setCarrierName', name);
  },
  setCarrierSCAC({ commit }, scac) {
    commit('setCarrierSCAC', scac);
  },
  setLocationId({ commit }, locationId) {
    commit('setLocationId', locationId);
  },
  setLocationGroups({ commit }, groups) {
    commit('setLocationGroups', groups);
  },
  setLocationDisplayName({ commit }, displayName) {
    commit('setLocationDisplayName', displayName);
  },
  setLocationType({ commit }, type) {
    commit('setLocationType', type);
  },
  setIsLoading({ commit }, value) {
    commit('setIsLoading', value);
  },
  setCustomer({ commit }, customer) {
    commit('setCustomer', customer);
  },
  setBookingNumber({ commit }, bookingNumber) {
    commit('setBookingNumber', bookingNumber);
  },
};

const mutations = {
  setGateEntry(state, gateEntry) {
    state.gateEntry = gateEntry;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setDirection(state, direction) {
    state.gateEntry.direction = direction;
  },
  setEquipmentId(state, id) {
    state.gateEntry.equipment.id = id;
  },
  setEquipmentSize(state, size) {
    state.gateEntry.equipment.size = size;
  },
  setEquipmentState(state, equipmentState) {
    state.gateEntry.equipment.state = equipmentState;
  },
  setEquipmentNumber(state, number) {
    state.gateEntry.equipment.number = number;
  },
  setEquipmentType(state, type) {
    state.gateEntry.equipment.type = type;
  },
  setEquipment(state, equipment) {
    state.gateEntry.equipment = equipment;
  },
  setCarrierName(state, name) {
    state.gateEntry.equipment.carrier.name = name;
  },
  setCarrierSCAC(state, scac) {
    state.gateEntry.equipment.carrier.scac = scac;
  },
  setLocationId(state, locationId) {
    state.gateEntry.location.id = locationId;
  },
  setLocationGroups(state, groups) {
    state.gateEntry.location.groups = groups;
  },
  setLocationDisplayName(state, displayName) {
    state.gateEntry.location.displayName = displayName;
  },
  setLocationType(state, type) {
    state.gateEntry.location.type = type;
  },
  setIsLoading(state, value) {
    state.isLoading = value;
  },
  setCustomer(state, customer) {
    state.gateEntry.equipment.customer.name = customer;
  },
  setBookingNumber(state, bookingNumber) {
    state.gateEntry.equipment.bookingNumber = bookingNumber;
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};

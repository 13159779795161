import Vue from 'vue';
import equipmentLogMapper from '../mappers/equipmentLogMapper';

function getLog(searchField) {
  const requestParams = {
    params: {
    },
  };

  if (searchField && searchField.name === 'Equipment Number') {
    requestParams.params.EquipmentNumber = searchField.value;
  }

  if (searchField && searchField.name === 'Carrier Name') {
    requestParams.params.CarrierName = searchField.value;
  }

  if (searchField && searchField.name === 'Carrier SCAC') {
    requestParams.params.SCAC = searchField.value;
  }

  return Vue.axios.get('equipment/log', requestParams).then((result) => {
    if (result.data) {
      return equipmentLogMapper.mapFromApiLogEntries(result.data);
    }
    return [];
  });
}

function getInaccuracies(startDateFilter, endDateFilter) {
  const requestParams = {
    params: {
    },
  };
  requestParams.params.StartDate = startDateFilter.toISOString();
  requestParams.params.EndDate = endDateFilter.toISOString();

  return Vue.axios.get('inaccuracies', requestParams).then((result) => {
    if (result.data) {
      const inaccuracies = equipmentLogMapper.mapFromApiInaccuracies(result.data);
      const inaccurateLocationsCount = result.data.InaccurateLocationsCount;
      return { inaccuracies, inaccurateLocationsCount };
    }
    return { inaccuracies: [], inaccurateLocationsCount: 0 };
  });
}

export default {
  getLog,
  getInaccuracies,
};

import router from '../router/index';
import yardService from '../services/yardService';
import yardMapper from '../mappers/yardMapper';

const getDefaultState = () => ({
  yard: {
    name: '',
    yardId: '',
    groups: [{
      name: '',
      rangeStart: '',
      rangeEnd: '',
      type: '',
    }],
    appointmentStartTime: '',
    appointmentEndTime: '',
    maxAppointmentOverlap: null,
  },
  yards: [],
  isLoading: false,
  showYardBuilderComponent: false,
  filter: '',
});

const state = getDefaultState();

const getters = {
  yards(state) {
    return state.yards;
  },
  isLoading(state) {
    return state.isLoading;
  },
  filter() {
    return state.filter;
  },
  showYardBuilderComponent() {
    return state.showYardBuilderComponent;
  },
  filteredYards(state, getters) {
    return getters.yards.filter((yard) => (yard.name && yard.name.toLowerCase().includes(state.filter.toLowerCase()))
    || (yard.yardId && yard.yardId.toLowerCase().includes(state.filter.toLowerCase())));
  },
  yard() {
    return state.yard;
  },
};

const actions = {
  async getYards({ commit }) {
    commit('setIsLoading', true);
    const yards = await yardService.getYards();
    commit('addYards', yards);
    commit('setIsLoading', false);
  },
  async createYard({ commit }, yard) {
    yardService.createYard(yard).then((data) => {
      commit('addYard', data);
    });
  },
  resetState({ commit, dispatch }) {
    commit('resetState');
    if (router.currentRoute.name === 'ManageYardsView') {
      dispatch('getYards');
    }
  },
  setFilter({ commit }, filter) {
    commit('setFilter', filter);
  },
  setShowYardBuilderComponent({ commit }, value) {
    commit('setShowYardBuilderComponent', value);
  },
  addToGroups({ commit }, group) {
    commit('addToGroups', group);
  },
  setYardBuilder({ commit }) {
    commit('setYardBuilder');
  },
};

const mutations = {
  addYards(state, yards) {
    state.yards = yards;
  },
  addYard(state, yard) {
    state.yards.push(yard);
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setIsLoading(state, value) {
    state.isLoading = value;
  },
  setFilter(state, value) {
    state.filter = value;
  },
  setShowYardBuilderComponent(state, value) {
    state.showYardBuilderComponent = value;
  },
  addToGroups(state, group) {
    state.yard.groups.push(group);
  },
  setYardBuilder(state) {
    state.yard = yardMapper.mapDefault();
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

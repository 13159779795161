import Vue from 'vue';
import router from '@/router';
import store from '@/store';

function getHeaders() {
  let headers = {};
  const user = JSON.parse(localStorage.getItem('user'));
  if (user && user.token) {
    let yardId = user.defaultYardId;
    const selectedYard = JSON.parse(localStorage.getItem('selectedYard'));
    if (selectedYard) {
      yardId = selectedYard.yardId;
    }
    headers = {
      Token: user.token,
      YardId: yardId,
    };
  }

  return headers;
}

function addHeadersToRequests() {
  Vue.axios.interceptors.request.use((req) => {
    req.headers = getHeaders();
    return req;
  });
}

function handleErrors() {
  Vue.axios.interceptors.response.use((response) => response, (error) => {
    let errorMessage = `There is an error with the network. Please contact your supervisor for further assistance.\n${error}`;

    if (error.response && error.response.status === 401) {
      store.dispatch('account/logout');
      if (router.currentRoute.name.toLowerCase() !== 'login') {
        router.push('/login');
      }
      errorMessage = (error.response.data && error.response.data.message) || error.response.statusText;
    }

    if (error.response && error.response.status === 409) {
      errorMessage = (error.response.data && error.response.data.message) || error.response.statusText;
    }

    store.commit('alert/error', errorMessage, { root: true });
    return Promise.reject(error);
  });
}

function configureAxios() {
  Vue.axios.defaults.baseURL = process.env.VUE_APP_YMS_API;
  addHeadersToRequests();
  handleErrors();
}

export default configureAxios;

import equipmentMapper from '../mappers/equipmentMapper';

const getDefaultState = () => ({
  showVerifyEquipmentDialog: false,
  showEquipmentNumberVerification: false,
  // TODO Mason - Rename
  equipmentNumberSearch: '',
  selectedEquipmentToMove: {},
  selectedExistingEquipment: {},
});

const state = getDefaultState();

const getters = {
  selectedEquipmentToMove(state) {
    return state.selectedEquipmentToMove;
  },
  selectedExistingEquipment(state) {
    return state.selectedExistingEquipment;
  },
  equipmentWithLocation(state, getters, rootState, rootGetters) {
    const locationsWithEquipment = rootGetters['location/locationsWithEquipment'];
    const equipment = equipmentMapper.mapLocationsToEquipmentWithLocation(locationsWithEquipment);
    return equipment;
  },
  equipmentWithLocationByType(state, getters, rootState, rootGetters) {
    const locationsWithEquipment = rootGetters['location/locationsWithEquipment'];
    const chosenType = rootGetters['gate/gateEntry'].equipment.type;
    const equipment = equipmentMapper.mapLocationsToEquipmentWithLocation(locationsWithEquipment);
    const equipmentByType = equipment.filter((value) => value.type === chosenType);
    return equipmentByType;
  },
  showVerifyEquipmentDialog(state) {
    return state.showVerifyEquipmentDialog;
  },
  equipmentNumberSearch(state) {
    return state.equipmentNumberSearch;
  },
  showEquipmentNumberVerification: (state) => state.showEquipmentNumberVerification,
};

const actions = {
  setShowVerifyEquipmentDialog({ commit }, value) {
    commit('setShowVerifyEquipmentDialog', value);
  },
  setEquipmentNumberSearch({ commit }, value) {
    commit('setEquipmentNumberSearch', value);
  },
  setEquipmentToMove({ commit }, equipment) {
    commit('setEquipmentToMove', equipment);
  },
  setSelectedExistingEquipment({ commit }, value) {
    commit('setSelectedExistingEquipment', value);
  },
  setShowEquipmentNumberVerification({ commit }, value) {
    commit('setShowEquipmentNumberVerification', value);
  },
};

const mutations = {
  setShowVerifyEquipmentDialog(state, value) {
    state.showVerifyEquipmentDialog = value;
  },
  setEquipmentNumberSearch(state, value) {
    state.equipmentNumberSearch = value;
  },
  setEquipmentToMove(state, equipment) {
    state.selectedEquipmentToMove = equipment;
  },
  setSelectedExistingEquipment(state, value) {
    state.selectedExistingEquipment = value;
  },
  setShowEquipmentNumberVerification(state, value) {
    state.showEquipmentNumberVerification = value;
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};

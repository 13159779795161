import { io } from 'socket.io-client';
import locationMapper from '../mappers/locationMapper';
import moveMapper from '../mappers/moveMapper';
import websocketEvents from '../constants/websocketEvents';
import checkMapper from '../mappers/checkMapper';
import websocketEventMapper from '../mappers/websocketEventMapper';
import equipmentMapper from '../mappers/equipmentMapper';
import appointmentMapper from '../mappers/appointmentMapper';

const getDefaultState = () => ({
  usersSocket: {},
  yardSocket: {},
});

const state = getDefaultState();

const getters = {
  usersSocket(state) {
    return state.usersSocket;
  },
  yardSocket(state) {
    return state.yardSocket;
  },
  isYardSocketConnected(state) {
    return state.yardSocket.connected;
  },
};

const actions = {
  connectToUsersWebSocket({ state, dispatch }) {
    const url = `${process.env.VUE_APP_SOCKET_ENDPOINT}/yard/users`;
    const token = process.env.VUE_APP_SOCKET_TOKEN;

    state.usersSocket = io(url, {
      auth: {
        token,
      },
      transports: ['websocket', 'polling'],
      withCredentials: true,
    });

    state.usersSocket.on('connect', () => {
      console.log('Users Connected');
      console.log(`Users Socket ID: ${state.usersSocket.id}`);
    });

    state.usersSocket.once('disconnect', (reason) => {
      if (reason === 'io server disconnect') {
        // the disconnection was initiated by the server, you need to reconnect manually
        state.usersSocket.connect();
      }

      if (reason !== 'io client disconnect') {
        const errorMessage = 'Something went wrong! Realtime updates are down. Reconnection is being attempted.';
        dispatch('alert/raiseError', errorMessage, { root: true });
      }
    });

    state.usersSocket.io.on('reconnect', (reason) => {
      console.log(`Reconnected to: ${reason}`);
      window.location.reload();
    });

    state.usersSocket.once('connect_error', (error) => {
      const errorMessage = `USER_SOCKET: Error Connecting To Realtime Update Service: ${error.message}`;
      dispatch('alert/raiseError', errorMessage, { root: true });
    });

    state.usersSocket.on(websocketEvents.USER_UPSERT, (user) => {
      dispatch('handleUserUpsert', user);
    });
  },
  connectToYardWebSocket({ state, dispatch }, yardId) {
    const url = `${process.env.VUE_APP_SOCKET_ENDPOINT}/yard-${yardId}`;
    const token = process.env.VUE_APP_SOCKET_TOKEN;

    state.yardSocket = io(url, {
      auth: {
        token,
      },
      transports: ['websocket', 'polling'],
      withCredentials: true,
    });

    state.yardSocket.on('connect', () => {
      console.log('Yard Connected');
      console.log(`Yard Socket ID: ${state.yardSocket.id}`);
    });

    state.yardSocket.once('disconnect', (reason) => {
      if (reason === 'io server disconnect') {
        // the disconnection was initiated by the server, you need to reconnect manually
        state.yardSocket.connect();
      }

      if (reason !== 'io client disconnect') {
        const errorMessage = 'Something went wrong! Realtime updates are down. Reconnection is being attempted.';
        dispatch('alert/raiseError', errorMessage, { root: true });
      }
    });

    state.yardSocket.io.on('reconnect', (reason) => {
      console.log(`Reconnected to: ${reason}`);
      window.location.reload();
    });

    state.yardSocket.once('connect_error', (error) => {
      const errorMessage = `YARD_SOCKET: Error Connecting To Realtime Update Service: ${error.message}`;
      dispatch('alert/raiseError', errorMessage, { root: true });
    });

    state.yardSocket.on(websocketEvents.LOCATION_EQUIPMENT, (locationEquipmentRequest) => {
      const request = locationMapper.mapToLocationEquipmentRequest(locationEquipmentRequest.locationID, locationEquipmentRequest.equipment);
      dispatch('handleLocationEquipment', request);
    });

    state.yardSocket.on(websocketEvents.LOCATION_EQUIPMENT_DELETE, (locationEquipmentRequest) => {
      const request = locationMapper.mapToLocationEquipmentRequest(locationEquipmentRequest.locationID, locationEquipmentRequest.equipment);
      dispatch('handleLocationEquipmentDelete', request);
    });

    state.yardSocket.on(websocketEvents.MOVE, (move) => {
      const mappedMove = moveMapper.mapFromApiMove(move);
      dispatch('handleMove', mappedMove);
    });

    state.yardSocket.on(websocketEvents.MOVE_DELETE, (move) => {
      const mappedMove = moveMapper.mapFromApiMove(move);
      dispatch('handleMoveDelete', mappedMove);
    });

    state.yardSocket.on(websocketEvents.YARD_CHECK, (check) => {
      const mappedCheck = checkMapper.mapFromApiCheck(check);
      dispatch('handleYardCheck', mappedCheck);
    });

    state.yardSocket.on(websocketEvents.YARD_CHECK_CONFIRMED_EQUIPMENT, (eventData) => {
      const mappedEventData = websocketEventMapper.mapLocationEquipmentForCheck(eventData);
      dispatch('handleYardCheckConfirmedEquipment', mappedEventData);
    });

    state.yardSocket.on(websocketEvents.YARD_CHECK_CONFIRMED_EQUIPMENT_DELETE, (eventData) => {
      const mappedEventData = websocketEventMapper.mapLocationEquipmentForCheck(eventData);
      dispatch('handleYardCheckConfirmedEquipmentDelete', mappedEventData);
    });

    state.yardSocket.on(websocketEvents.YARD_CHECK_CONFIRMED_LOCATION, (eventData) => {
      const mappedEventData = websocketEventMapper.mapConfirmedLocationRequestForCheck(eventData);
      dispatch('handleYardCheckConfirmedLocation', mappedEventData);
    });

    state.yardSocket.on(websocketEvents.YARD_CHECK_CONFIRMED_LOCATION_DELETE, (eventData) => {
      const mappedEventData = websocketEventMapper.mapConfirmedLocationRequestForCheck(eventData);
      dispatch('handleYardCheckConfirmedLocationDelete', mappedEventData);
    });

    state.yardSocket.on(websocketEvents.YARD_CHECKS_CONFIRMED_EQUIPMENT, (eventData) => {
      const mappedEventData = websocketEventMapper.mapLocationEquipmentForChecks(eventData);
      dispatch('handleYardChecksConfirmedEquipment', mappedEventData);
    });

    state.yardSocket.on(websocketEvents.YARD_CHECKS_CONFIRMED_EQUIPMENT_DELETE, (eventData) => {
      const mappedEventData = websocketEventMapper.mapLocationEquipmentForChecks(eventData);
      dispatch('handleYardChecksConfirmedEquipmentDelete', mappedEventData);
    });

    state.yardSocket.on(websocketEvents.YARD_CHECKS_CONFIRMED_LOCATION, (eventData) => {
      const mappedEventData = websocketEventMapper.mapLocationEquipmentForChecks(eventData);
      dispatch('handleYardChecksConfirmedLocation', mappedEventData);
    });

    state.yardSocket.on(websocketEvents.YARD_CHECKS_CONFIRMED_LOCATION_DELETE, (eventData) => {
      const mappedEventData = websocketEventMapper.mapLocationEquipmentForChecks(eventData);
      dispatch('handleYardChecksConfirmedLocationDelete', mappedEventData);
    });

    state.yardSocket.on(websocketEvents.LOST_EQUIPMENT, (equipment) => {
      const mappedEquipment = equipmentMapper.mapFromApiEquipment(equipment);
      dispatch('handleLostEquipment', mappedEquipment);
    });

    state.yardSocket.on(websocketEvents.LOST_EQUIPMENT_DELETE, (equipment) => {
      const mappedEquipment = equipmentMapper.mapFromApiEquipment(equipment);
      dispatch('handleLostEquipmentDelete', mappedEquipment);
    });

    state.yardSocket.on(websocketEvents.EQUIPMENT, (equipment) => {
      const mappedEquipment = equipmentMapper.mapFromApiEquipment(equipment);
      dispatch('handleEquipment', mappedEquipment);
    });

    state.yardSocket.on(websocketEvents.APPOINTMENT_CREATED, (apiAppointment) => {
      const appointment = appointmentMapper.mapFromApiAppointment(apiAppointment);
      dispatch('appointment/appointmentAdded', appointment, { root: true });
    });

    state.yardSocket.on(websocketEvents.APPOINTMENT_UPDATED, (apiAppointment) => {
      const appointment = appointmentMapper.mapFromApiAppointment(apiAppointment);
      dispatch('appointment/appointmentUpdated', appointment, { root: true });
    });

    state.yardSocket.on(websocketEvents.APPOINTMENT_DELETED, (apiAppointmentId) => {
      console.log('In websockets: ', apiAppointmentId);
      dispatch('appointment/appointmentCanceled', apiAppointmentId, { root: true });
    });
  },
  disconnectFromYardWebSocket({ state }) {
    if (state.yardSocket) {
      if (state.yardSocket.connected) {
        state.yardSocket.disconnect();
        state.yardSocket = null;
        console.log(('Yard Socket has disconnected'));
      }
    }
  },
  disconnectFromUsersWebSocket({ state }) {
    if (state.usersSocket) {
      if (state.usersSocket.connected) {
        state.usersSocket.disconnect();
        state.usersSocket = null;
        console.log(('Users Socket has disconnected'));
      }
    }
  },
  handleLocationEquipment({ dispatch, rootGetters }, request) {
    const existingEquipment = rootGetters['location/equipmentById'](request.equipment.id);
    if (existingEquipment) {
      dispatch('location/updatedEquipmentLocation', request, { root: true });
    } else {
      dispatch('location/addEquipmentToLocation', request, { root: true });
    }
  },
  handleLocationEquipmentDelete({ dispatch }, request) {
    dispatch('location/removeEquipmentFromLocation', request, { root: true });
  },
  handleMove({ dispatch, rootGetters }, move) {
    const existingPendingMove = rootGetters['move/pendingMoveById'](move.id);
    if (existingPendingMove) {
      if (move.completedDate != null) {
        dispatch('move/stoppedPendingMove', move, { root: true });
      } else {
        dispatch('move/updatedPendingMove', move, { root: true });
      }
    } else if (move.completedDate == null) {
      dispatch('move/addedPendingMove', move, { root: true });
    }
  },
  handleMoveDelete({ dispatch }, move) {
    dispatch('move/removedPendingMove', move, { root: true });
  },
  handleYardCheck({ dispatch, rootGetters }, check) {
    const existingActiveCheck = rootGetters['check/activeCheckById'](check.id);
    if (existingActiveCheck) {
      if (check.endTime != null && check.endTime !== '') {
        dispatch('check/stoppedActiveCheck', check, { root: true });
      }
    } else if (check.endTime == null || check.endTime === '') {
      dispatch('check/addedActiveCheck', check, { root: true });
    }
  },
  handleYardCheckConfirmedEquipment({ dispatch }, request) {
    dispatch('check/addedConfirmedEquipment', request, { root: true });
  },
  handleYardCheckConfirmedEquipmentDelete({ dispatch, rootGetters }, request) {
    const locationObject = rootGetters['location/locationById'](request.locationEquipmentRequest.locationID);
    dispatch('check/removedConfirmedEquipment', { checkID: request.checkID, equipment: request.locationEquipmentRequest.equipment, location: locationObject }, { root: true });
  },
  handleYardCheckConfirmedLocation({ dispatch }, request) {
    dispatch('check/addedConfirmedLocation', request, { root: true });
  },
  handleYardCheckConfirmedLocationDelete({ dispatch }, request) {
    dispatch('check/removedConfirmedLocation', request, { root: true });
  },
  handleYardChecksConfirmedEquipment({ dispatch }, request) {
    dispatch('check/addedConfirmedEquipments', request, { root: true });
  },
  handleYardChecksConfirmedEquipmentDelete({ dispatch }, request) {
    dispatch('check/removedConfirmedEquipments', request, { root: true });
  },
  handleYardChecksConfirmedLocation({ dispatch }, request) {
    dispatch('check/addedConfirmedLocations', request, { root: true });
  },
  handleYardChecksConfirmedLocationDelete({ dispatch }, request) {
    dispatch('check/removedConfirmedLocations', request, { root: true });
  },
  handleLostEquipment({ dispatch }, equipment) {
    dispatch('check/addedLostEquipment', equipment, { root: true });
  },
  handleLostEquipmentDelete({ dispatch }, equipment) {
    dispatch('check/removedLostEquipment', equipment, { root: true });
  },
  handleEquipment({ dispatch, rootGetters }, equipment) {
    const existingEquipment = rootGetters['location/equipmentById'](equipment.id);

    if (existingEquipment) {
      dispatch('location/updatedEquipment', equipment, { root: true });
    }
  },
  handleUserUpsert({ dispatch, rootGetters }, user) {
    const existingUser = rootGetters['admin/userById'](user.id);
    if (existingUser) {
      dispatch('admin/updatedUser', user, { root: true });
    } else {
      dispatch('admin/createdUser', user, { root: true });
    }
  },
};

const mutations = {

};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

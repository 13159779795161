import Vue from 'vue';
import equipmentMapper from '../mappers/equipmentMapper';
import equipmentStates from '../constants/equipmentStates';
import equipmentTypes from '../constants/equipmentTypes';

function getLostEquipment() {
  return Vue.axios.get('equipment/lost').then((result) => {
    if (result.data) {
      return equipmentMapper.mapFromApiLostEquipments(result.data);
    }
    return [];
  });
}

function getEquipmentStateOptions(equipmentType) {
  switch (equipmentType) {
    case equipmentTypes.CONTAINER:
    case equipmentTypes.REEFER_CONTAINER:
      return [equipmentStates.EMPTY, equipmentStates.LOADED];
    case equipmentTypes.CHASSIS:
      return [equipmentStates.BARE];
    case equipmentTypes.VAN:
    case equipmentTypes.REEFER_VAN:
    case equipmentTypes.FLATBED:
      return [equipmentStates.EMPTY, equipmentStates.PARTIAL, equipmentStates.LOADED, equipmentStates.READY];
    case equipmentTypes.TANKER:
      return [equipmentStates.EMPTY, equipmentStates.LOADED];
    default:
      return [];
  }
}

function getLoadedStates() {
  return [equipmentStates.PARTIAL, equipmentStates.LOADED, equipmentStates.READY];
}

function getEmptyStates() {
  return [equipmentStates.EMPTY, equipmentStates.BARE];
}

export default {
  getLostEquipment,
  getEquipmentStateOptions,
  getLoadedStates,
  getEmptyStates,
};

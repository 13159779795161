import locationMapper from './locationMapper';
import equipmentMapper from './equipmentMapper';

function mapFromApiMoves(apiMoves) {
  const moves = [];

  if (apiMoves) {
    apiMoves.forEach((apiMove) => {
      const move = this.mapFromApiMove(apiMove);
      moves.push(move);
    });
  }

  return moves;
}

function mapFromApiMove(apiMove) {
  const move = {
    id: apiMove.id,
    equipment: equipmentMapper.mapFromApiEquipment(apiMove.equipment),
    fromLocation: locationMapper.mapFromApiLocation(apiMove.fromLocation),
    toLocation: locationMapper.mapFromApiLocation(apiMove.toLocation),
    createdBy: apiMove.createdBy,
    createdDate: apiMove.createdDate,
    startDate: null,
    assignedTo: null,
    completedDate: null,
  };

  if (apiMove.startDate) {
    move.startDate = apiMove.startDate;
  }

  if (apiMove.assignedTo) {
    move.assignedTo = apiMove.assignedTo;
  }

  if (apiMove.completedDate) {
    move.completedDate = apiMove.completedDate;
  }

  return move;
}

export default {
  mapFromApiMove,
  mapFromApiMoves,
};

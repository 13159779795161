const state = {
  isSideNavVisible: false,
};

const mutations = {
  setIsSideNavVisible(state, value) {
    state.isSideNavVisible = value;
  },
};

const actions = {
  setIsSideNavVisible({ commit }, value) {
    commit('setIsSideNavVisible', value);
  },
};

const getters = {
  isSideNavVisible: (state) => state.isSideNavVisible,
};

export default {
  name: 'sidenav',
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

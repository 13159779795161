import Vue from 'vue';
import expectedGateActivityMapper from '../mappers/expectedGateActivityMapper';

async function getExpectedGateActivities(yardID) {
  const requestParams = {
    params: {
      YardID: yardID,
    },
  };
  const response = await Vue.axios.get('expectedGateActivity', requestParams);
  const apiExpectedGateActivities = response.data;
  const expectedGateActivities = expectedGateActivityMapper.mapFromApiExpectedGateActivities(apiExpectedGateActivities);
  return expectedGateActivities;
}

export default {
  getExpectedGateActivities,
};

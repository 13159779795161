const state = {
  filter: '',
};

const getters = {
  filter: (state) => state.filter,
};

const mutations = {
  setFilter(state, term) {
    state.filter = term;
  },
};

const actions = {
  setFilter({ commit }, value) {
    commit('setFilter', value);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

import _ from 'lodash';
import locationMapper from './locationMapper';
import moveMapper from './moveMapper';
import equipmentMapper from './equipmentMapper';
import direction from '../constants/direction';

function mapToApiGateEntry(gateEntry) {
  const apiGateEntry = {
    direction: gateEntry.direction,
    locationEquipmentRequest: {
      locationID: gateEntry.location.id,
      equipment: gateEntry.equipment,
    },
    enteredBy: gateEntry.enteredBy,
  };

  if (gateEntry.direction === direction.IN) {
    apiGateEntry.locationEquipmentRequest.equipment.arrivalDate = new Date().toISOString();
  }

  return apiGateEntry;
}

function mapFromGateOutResponse(gateOutResponse) {
  if (gateOutResponse.removedLocationEquipment.locationID === '' && gateOutResponse.lostEquipmentList === null) {
    return null;
  }
  const result = {
    removedLocationEquipment: {
      locationID: gateOutResponse.removedLocationEquipment.locationID,
      equipment: equipmentMapper.mapFromApiEquipment(gateOutResponse.removedLocationEquipment.equipment),
    },
    lostEquipmentList: equipmentMapper.mapFromApiEquipments(gateOutResponse.lostEquipmentList),
    pendingMoves: moveMapper.mapFromApiMoves(gateOutResponse.pendingMoves),
  };

  return result;
}

function mapFromGateInResponse(gateInResponse) {
  const result = {
    locationID: gateInResponse.locationID,
    equipment: equipmentMapper.mapFromApiEquipment(gateInResponse.equipment),
  };

  return result;
}

function getDefaultGateEntry() {
  const equipment = _.cloneDeep(equipmentMapper.defaultEquipment);
  const location = _.cloneDeep(locationMapper.defaultLocation);

  const entry = {
    direction: direction.IN,
    location,
    equipment,
  };

  return entry;
}

export default {
  mapToApiGateEntry,
  mapFromGateOutResponse,
  mapFromGateInResponse,
  getDefaultGateEntry,
};

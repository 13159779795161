import locationMapper from './locationMapper';
import equipmentMapper from './equipmentMapper';

function formatDate(date) {
  const d = new Date(date);
  if (Number.isNaN(d.valueOf())) {
    return '';
  }
  return d.toLocaleString('en-US', { timeZone: 'America/New_York' });
}

function mapFromApiChecks(apiChecks) {
  const checks = [];

  apiChecks.forEach((apiCheck) => {
    const check = this.mapFromApiCheck(apiCheck);
    checks.push(check);
  });

  return checks;
}

function mapFromApiCheck(apiCheck) {
  const check = {
    id: apiCheck.id,
    startTime: apiCheck.startTime,
    formattedStartDate: formatDate(apiCheck.startTime),
    endTime: apiCheck.endTime,
    formattedEndDate: formatDate(apiCheck.endTime),
    startedBy: apiCheck.startedBy,
    endedBy: apiCheck.endedBy,
    confirmedEquipment: [],
    confirmedLocations: [],
  };

  if (apiCheck.confirmedEquipment) {
    check.confirmedEquipment = equipmentMapper.mapFromApiEquipments(apiCheck.confirmedEquipment);
  }

  if (apiCheck.confirmedLocations) {
    check.confirmedLocations = locationMapper.mapFromApiLocations(apiCheck.confirmedLocations);
  }

  return check;
}

export default {
  mapFromApiChecks,
  mapFromApiCheck,
};

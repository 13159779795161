import _ from 'lodash';
import checkStatuses from '../constants/checkStatuses';
import equipmentMapper from '../mappers/equipmentMapper';

const getDefaultState = () => ({
  filter: '',
  selectedGroup: '',
});

const state = getDefaultState();

const getters = {
  filter(state) {
    return state.filter;
  },
  selectedGroup(state) {
    return state.selectedGroup;
  },
  expandedCheckEntries(state, getters) {
    return getters.checkEntries.filter((data) => data.status === '');
  },
  expandedCheckEntriesEquipment(state, getters) {
    return getters.checkEntriesEquipment.filter((data) => data.status === '');
  },
  checkEntriesByLocationGroup: (state, getters) => (groupName) => getters.checkEntries.filter((w) => w.location.groups.some((w) => w.toLowerCase() === groupName.toLowerCase())),
  filteredCheckEntries: (state, getters) => (searchTerm) => getters.checkEntries.filter((item) => (item.equipment.number && item.equipment.number.toLowerCase().includes(searchTerm.toLowerCase()))
        || item.location.displayName.indexOf(searchTerm.toLowerCase()) >= 0
        || (item.location.equipment.find((equipment) => equipment.number.toLowerCase().includes(searchTerm.toLowerCase())))
        || (item.location.equipment.find((equipment) => equipment.carrier.scac.toLowerCase().includes(searchTerm.toLowerCase())))
        || (item.location.equipment.find((equipment) => equipment.customer.name.toLowerCase().includes(searchTerm.toLowerCase())))
        || (item.location.equipment.find((equipment) => equipment.bookingNumber.toLowerCase().includes(searchTerm.toLowerCase())))
        || (item.location.equipment.find((equipment) => equipment.carrier.name.toLowerCase().includes(searchTerm.toLowerCase())))
        || (item.groups && item.groups.some((w) => w.toLowerCase().includes(searchTerm.toLowerCase())))
        || (item.equipment && item.equipment.carrier.scac.toLowerCase().includes(searchTerm.toLowerCase()))
        || (item.equipment && item.equipment.customer.name.toLowerCase().includes(searchTerm.toLowerCase()))
        || (item.equipment && item.equipment.bookingNumber.toLowerCase().includes(searchTerm.toLowerCase()))
        || (item.equipment && item.equipment.carrier.name.toLowerCase().includes(searchTerm.toLowerCase()))),
  checkEntries(state, getters, rootState, rootGetters) {
    const checkEntries = [];
    const locations = rootGetters['location/locations'];

    for (let i = 0; i < locations.length; i += 1) {
      const equipment = _.cloneDeep(equipmentMapper.defaultEquipment);

      if (locations[i].equipment.length > 1 && locations[i].equipmentCapacity > 1) {
        if (locations[i].equipment.length >= locations[i].equipmentCapacity) {
          let numberOfConfirmed = 0;
          for (let j = 0; j < locations[i].equipment.length; j += 1) {
            if (locations[i].equipment[j].status === checkStatuses.CONFIRMED) {
              numberOfConfirmed += 1;
            }
          }
          if (numberOfConfirmed === locations[i].equipment.length) {
            locations[i].status = checkStatuses.CONFIRMED;
          }
        }
      }

      if (locations[i].equipmentCapacity > 1 || locations[i].equipment.length === 0) {
        const checkEntry = {
          id: locations[i].id,
          status: locations[i].status,
          location: locations[i],
          equipment,
        };
        if (checkEntry.status !== '' && locations[i].equipment.length > 0) {
          checkEntry.status = checkStatuses.CONFIRMED;
        }

        checkEntries.push(checkEntry);
      } else {
        const checkEntry = {
          id: locations[i].id,
          status: locations[i].equipment[0].status,
          location: locations[i],
          equipment: locations[i].equipment[0],
        };

        checkEntries.push(checkEntry);
      }
    }

    return checkEntries;
  },
  checkEntriesEquipment(state, getters) {
    const checkEntriesEquipment = [];
    const { checkEntries } = getters;

    for (let i = 0; i < checkEntries.length; i += 1) {
      for (let j = 0; j < checkEntries[i].location.equipment.length; j += 1) {
        checkEntriesEquipment.push(checkEntries[i].location.equipment[j]);
      }
    }
    return checkEntriesEquipment;
  },
};

const actions = {
  setSelectedGroup({ commit }, group) {
    commit('setSelectedGroup', group);
  },
  setFilter({ commit }, filter) {
    commit('setFilter', filter);
  },
};

const mutations = {
  setFilter(state, value) {
    state.filter = value;
  },
  setSelectedGroup(state, selectedGroup) {
    state.selectedGroup = selectedGroup;
  },
};

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations,
};

import Vue from 'vue';
import locationMapper from '../mappers/locationMapper';
import moveMapper from '../mappers/moveMapper';
import yardMapper from '../mappers/yardMapper';

function getYard() {
  return Vue.axios.get('yard').then((result) => {
    const response = {};
    response.locations = locationMapper.mapFromApiLocations(result.data.locations);
    response.scheduledMoves = moveMapper.mapFromApiMoves(result.data.scheduledMoves);
    response.groups = result.data.groups;
    response.appointmentStartTime = result.data.appointmentStartTime;
    response.appointmentEndTime = result.data.appointmentEndTime;
    response.appointmentTimeIntervalInMinutes = result.data.appointmentTimeIntervalInMinutes;
    response.maxAppointmentOverlap = result.data.maxAppointmentOverlap;
    return response;
  });
}

function getYards() {
  return Vue.axios.get('yard/configs').then((result) => {
    if (result.data) {
      return yardMapper.mapFromApiYards(result.data);
    }
    return [];
  });
}

function createYard(yard) {
  const mappedYard = yardMapper.mapToApiYard(yard);
  return Vue.axios.post('yard', mappedYard).then((result) => {
    if (result.data) {
      return yardMapper.mapFromApiYard(result.data);
    }
    return {};
  });
}

export default {
  getYard,
  getYards,
  createYard,
};

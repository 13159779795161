import equipmentMapper from './equipmentMapper';
import locationMapper from './locationMapper';

function mapLocationEquipmentForCheck(eventData) {
  const checkRequest = {
    checkID: eventData.checkID,
    locationEquipmentRequest: locationMapper.mapFromApiLocationEquipmentRequest(eventData.locationEquipmentRequest),
  };

  return checkRequest;
}

function mapConfirmedLocationRequestForCheck(eventData) {
  const request = {
    checkID: eventData.checkID,
    location: locationMapper.mapFromApiLocation(eventData.location),
  };

  return request;
}

function mapLocationEquipmentForChecks(eventData) {
  const checkRequests = [];

  for (let i = 0; i < eventData.checkIDs.length; i += 1) {
    const checkRequest = {
      checkID: eventData.checkIDs[i],
      location: locationMapper.mapFromApiLocation(eventData.location),
      equipment: equipmentMapper.mapFromApiEquipment(eventData.equipment),
    };

    checkRequests.push(checkRequest);
  }

  return checkRequests;
}

export default {
  mapLocationEquipmentForCheck,
  mapLocationEquipmentForChecks,
  mapConfirmedLocationRequestForCheck,
};

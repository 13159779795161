const state = {
  alerts: [],
};

const getters = {
  alerts(state) { return state.alerts; },
};

const actions = {
  raiseError({ commit }, message) {
    commit('error', message);
  },
  raiseSuccess({ commit }, message) {
    commit('success', message);
  },
  raiseInformation({ commit }, message) {
    commit('information', message);
  },
  clearFirst({ commit }) {
    commit('clearFirst');
  },
  clearAll({ commit }) {
    commit('clearAll');
  },
};

const mutations = {
  error: (state, message) => {
    const alert = {
      type: 'Error',
      color: 'red lighten-3',
      message,
    };

    state.alerts.push(alert);
  },
  success: (state, message) => {
    const alert = {
      type: 'Success',
      color: 'green lighten-3',
      message,
    };

    state.alerts.push(alert);
  },
  information: (state, message) => {
    const alert = {
      type: '',
      color: 'yellow lighten-3',
      message,
    };

    state.alerts.push(alert);
  },
  clearFirst: (state) => {
    state.alerts.pop();
  },
  clearAll: (state) => {
    state.alerts = [];
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

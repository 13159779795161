function mapToApiCustomer(customer) {
  const apiCustomer = {
    name: customer.name,
  };

  if (customer.id) {
    apiCustomer.id = customer.id;
  }

  return apiCustomer;
}

function mapFromApiCustomer(apiCustomer) {
  const customer = {
    id: apiCustomer.id,
    name: apiCustomer.name,
  };

  return customer;
}

export default {
  mapFromApiCustomer,
  mapToApiCustomer,
};

import Vue from 'vue';
import axios from 'axios';

function resetPassword(request) {
  return Vue.axios.post('users/resetPassword', request);
}

function requestPasswordReset(request) {
  return Vue.axios.post('users/requestPasswordReset', request);
}

async function login({ email, password }) {
  const response = await Vue.axios.post('users/authenticate', { email, password });
  if (axios.defaults.validateStatus(response.status)) {
    if (response.data.token) {
      response.data.user.token = response.data.token;
      localStorage.setItem('user', JSON.stringify(response.data.user));
    }
  }

  return response.data;
}

function logout() {
  localStorage.removeItem('user');
  localStorage.removeItem('selectedYard');
}

async function getUsers() {
  const response = await Vue.axios.get('users');
  return response.data;
}

function createUser(user, password, enteredBy) {
  const request = {
    user,
    password,
    enteredBy,
  };
  return Vue.axios.post('users', request).then((result) => result.data);
}

function updateUser(user, password, enteredBy) {
  const request = {
    user,
    password,
    enteredBy,
  };
  return Vue.axios.put(`/users/${request.user.id}`, request).then((result) => result.data);
}

export default {
  resetPassword,
  requestPasswordReset,
  login,
  logout,
  getUsers,
  createUser,
  updateUser,
};

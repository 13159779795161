const REFERENCE_NUMBER = 'Reference Number';
const PICK_UP_NUMBER = 'Pick Up Number';
const DELIVERY_ORDER_NUMBER = 'Delivery Order Number';
const SO_NUMBER = 'S.O. Number';
const PO_NUMBER = 'P.O. Number';
const BOL = 'BOL';
const TMS_REFERENCE_NUMBER = 'TMS Reference Number';
const BROKERAGE_REFERENCE_NUMBER = 'Brokerage Reference Number';

export default {
  REFERENCE_NUMBER,
  PICK_UP_NUMBER,
  DELIVERY_ORDER_NUMBER,
  SO_NUMBER,
  PO_NUMBER,
  BOL,
  TMS_REFERENCE_NUMBER,
  BROKERAGE_REFERENCE_NUMBER,
};

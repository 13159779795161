import expectedGateActivityService from '../services/expectedGateActivityService';

const defaultState = {
  selectedGateActivity: null,
  expectedGateActivities: [],
  isLoading: false,
  expectedEquipmentNumberFilter: '',
  expectedEquipmentTypeFilter: '',
};

const state = defaultState;

const getters = {
  selectedGateActivity: (state) => state.selectedGateActivity,
  expectedGateActivities: (state) => state.expectedGateActivities,
  expectedEquipmentNumberFilter: (state) => state.expectedEquipmentNumberFilter,
  isLoading: (state) => state.isLoading,
  filteredExpectedGateActivity: (state, getters, rootState, rootGetters) => {
    const filter = state.expectedEquipmentNumberFilter;

    // Chris told me to make the UX worse.
    if (!filter || filter === '') {
      return [];
    }

    let filteredExpectedGateActivity = [...state.expectedGateActivities];

    const equipmentType = rootGetters['gate/gateEntry'].equipment.type;

    if (equipmentType && equipmentType !== '') {
      filteredExpectedGateActivity = filteredExpectedGateActivity.filter((ega) => ega.equipment.type === equipmentType);
    }

    if (filter && filter !== '') {
      filteredExpectedGateActivity = filteredExpectedGateActivity.filter((ega) => ega.equipment.number.includes(state.expectedEquipmentNumberFilter.toUpperCase()));
    }

    return filteredExpectedGateActivity;
  },
};

const actions = {
  async getExpectedGateActivities({ commit, rootGetters }) {
    commit('setIsLoading', true);
    if (rootGetters['account/selectedYard']) {
      const result = await expectedGateActivityService.getExpectedGateActivities(rootGetters['account/selectedYard'].yardId);
      commit('setExpectedGateActivities', result);
    }
    commit('setIsLoading', false);
  },
  resetState({ commit }) {
    commit('resetState');
    commit('setExpectedGateActivities', []);
  },
  setExpectedEquipmentNumberFilter({ commit }, value) {
    commit('setExpectedEquipmentNumberFilter', value);
  },
  setSelectedGateActivity({ commit }, value) {
    commit('setSelectedGateActivity', value);
  },
};

const mutations = {
  setSelectedGateActivity(state, selectedGateActivity) {
    state.selectedGateActivity = selectedGateActivity;
  },
  setExpectedGateActivities(state, expectedGateActivities) {
    state.expectedGateActivities = expectedGateActivities;
  },
  setIsLoading(state, value) {
    state.isLoading = value;
  },
  resetState(state) {
    Object.assign(state, defaultState);
  },
  setExpectedEquipmentNumberFilter(state, value) {
    state.expectedEquipmentNumberFilter = value;
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};

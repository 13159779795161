import router from '../router/index';
import logService from '../services/equipmentLogService';

const getDefaultState = () => ({
  log: [],
  inaccuracies: [],
  inaccurateLocationsCount: 0,
  searchableFields: [
    'Equipment Number',
    'Carrier SCAC',
    'Carrier Name',
  ],
  selectedSearchableField: {
    name: '',
    value: '',
  },
  isLoading: false,
  equipmentIdFilter: '',
  startDateFilter: '',
  endDateFilter: '',
});

const state = getDefaultState();

const getters = {
  searchableFields() {
    return state.searchableFields;
  },
  selectedSearchableField() {
    return state.selectedSearchableField;
  },
  log() {
    return state.log;
  },
  inaccuracies() {
    return state.inaccuracies;
  },
  inaccurateLocationsCount() {
    return state.inaccurateLocationsCount;
  },
  isLoading() {
    return state.isLoading;
  },
  filteredByEquipmentId() {
    return state.log.filter((logEntry) => logEntry.equipment.id === state.equipmentIdFilter);
  },
  startDateFilter() {
    return state.startDateFilter;
  },
  endDateFilter() {
    return state.endDateFilter;
  },
};

const actions = {
  async getLog({ commit }) {
    commit('setIsLoading', true);
    const result = await logService.getLog(state.selectedSearchableField);
    commit('setLog', result);
    commit('setIsLoading', false);
  },
  async getInaccuracies({ commit }) {
    commit('setIsLoading', true);
    const result = await logService.getInaccuracies(state.startDateFilter, state.endDateFilter);
    commit('setInaccuracies', result.inaccuracies);
    commit('setinaccurateLocationsCount', result.inaccurateLocationsCount);
    commit('setIsLoading', false);
  },
  resetState({ commit, dispatch }) {
    commit('resetState');
    if (router.currentRoute.name === 'EquipmentLogView') {
      dispatch('getLog');
    }
  },
  setSelectedSearchableFieldName({ commit }, name) {
    commit('setSelectedSearchableFieldName', name);
  },
  setEquipmentIdFilter({ commit }, value) {
    commit('setEquipmentIdFilter', value);
  },
  setStartDateFilter({ commit }, value) {
    commit('setStartDateFilter', value);
  },
  setEndDateFilter({ commit }, value) {
    commit('setEndDateFilter', value);
  },
};

const mutations = {
  setLog(state, log) {
    state.log = log;
  },
  setInaccuracies(state, inaccuracies) {
    state.inaccuracies = inaccuracies;
  },
  setinaccurateLocationsCount(state, inaccurateLocationsCount) {
    state.inaccurateLocationsCount = inaccurateLocationsCount;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setIsLoading(state, value) {
    state.isLoading = value;
  },
  setSelectedSearchableFieldName(state, name) {
    state.selectedSearchableField.name = name;
  },
  setEquipmentIdFilter(state, value) {
    state.equipmentIdFilter = value;
  },
  setStartDateFilter(state, value) {
    state.startDateFilter = new Date(value);
  },
  setEndDateFilter(state, value) {
    state.endDateFilter = new Date(value);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

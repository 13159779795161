import equipmentMapper from './equipmentMapper';

const defaultExpectedGateActivity = {
  id: '',
  equipment: {
    id: '',
    size: '',
    number: '',
    state: '',
    type: '',
    carrier: {
      id: '',
      scac: '',
      name: '',
    },
    arrivalDate: null,
    customer: {
      id: '',
      name: '',
    },
    bookingNumber: '',
    status: '',
    hasPendingMove: false,
  },
  yardID: '',
  direction: '',
  externalID: '',
  systemOfOrigin: '',
};

function mapFromApiExpectedGateActivities(apiExpectedGateActivities) {
  const expectedGateActivities = [];

  if (apiExpectedGateActivities) {
    apiExpectedGateActivities.forEach((apiExpectedGateActivity) => {
      const expectedGateActivity = this.mapFromApiExpectedGateActivity(apiExpectedGateActivity);
      expectedGateActivities.push(expectedGateActivity);
    });
  }

  return expectedGateActivities;
}

function mapFromApiExpectedGateActivity(apiExpectedGateActivity) {
  const expectedGateActivity = {
    id: apiExpectedGateActivity.id,
    equipment: equipmentMapper.mapFromApiEquipment(apiExpectedGateActivity.equipment),
    yardID: apiExpectedGateActivity.yardID,
    direction: apiExpectedGateActivity.direction,
    externalID: apiExpectedGateActivity.externalID,
    systemOfOrigin: apiExpectedGateActivity.systemOfOrigin,
  };

  return expectedGateActivity;
}

export default {
  defaultExpectedGateActivity,
  mapFromApiExpectedGateActivities,
  mapFromApiExpectedGateActivity,
};

import locationMapper from './locationMapper';
import equipmentMapper from './equipmentMapper';

function formatDate(date) {
  if (date !== '0001-01-01T00:00:00Z') {
    const d = new Date(date);
    return d.toLocaleString('en-US', { timeZone: 'America/New_York' });
  }
  return null;
}

function mapByType(logEntry, apiLogEntry) {
  switch (apiLogEntry.type) {
    case 'GATE_IN':
      logEntry.type = 'Gate In';
      logEntry.direction = 'In';
      if (apiLogEntry.details) {
        logEntry.previousLocation = locationMapper.mapFromApiLocation(apiLogEntry.details);
      }
      break;
    case 'GATE_OUT':
      logEntry.type = 'Gate Out';
      logEntry.direction = 'Out';
      break;
    case 'GATE_OUT_EQUIPMENT_ADD':
      logEntry.type = 'Gate Out Equipment Added';
      logEntry.direction = 'Out';
      break;
    case 'LOST_EQUIPMENT_GATE_OUT':
      logEntry.type = 'Gate Out Lost Equipment';
      logEntry.direction = 'Out';
      break;
    case 'LOST_EQUIPMENT':
      logEntry.type = 'Equipment Marked Lost';
      if (apiLogEntry.details) {
        logEntry.previousLocation = locationMapper.mapFromApiLocation(apiLogEntry.details);
      }
      break;
    case 'LOST_EQUIPMENT_DELETE':
      logEntry.type = 'Lost Equipment Deleted';
      break;
    case 'MOVE_CREATED':
      logEntry.type = 'Move Created';
      if (apiLogEntry.details) {
        logEntry.previousLocation = locationMapper.mapFromApiLocation(apiLogEntry.details.previousLocation);
        logEntry.updatedLocation = locationMapper.mapFromApiLocation(apiLogEntry.details.updatedLocation);
      }
      break;
    case 'MOVE_CHANGED':
      logEntry.type = 'Jockey Move Changed';
      if (apiLogEntry.details) {
        logEntry.previousLocation = locationMapper.mapFromApiLocation(apiLogEntry.details.previousLocation);
        logEntry.updatedLocation = locationMapper.mapFromApiLocation(apiLogEntry.details.updatedLocation);
      }
      break;
    case 'MOVE_COMPLETED':
      logEntry.type = 'Move Completed';
      if (apiLogEntry.details) {
        logEntry.previousLocation = locationMapper.mapFromApiLocation(apiLogEntry.details.previousLocation);
        logEntry.updatedLocation = locationMapper.mapFromApiLocation(apiLogEntry.details.updatedLocation);
      }
      break;
    case 'MOVE_DELETED':
      logEntry.type = 'Move Deleted';
      break;
    case 'YARD_CHECK_EQUIPMENT_MOVED':
      logEntry.type = 'Yard Check Equipment Location Updated';
      if (apiLogEntry.details) {
        logEntry.previousLocation = locationMapper.mapFromApiLocation(apiLogEntry.details.previousLocation);
        logEntry.updatedLocation = locationMapper.mapFromApiLocation(apiLogEntry.details.updatedLocation);
      }
      break;
    case 'YARD_CHECK_CONFIRMED_EQUIPMENT':
      logEntry.type = 'Yard Check Equipment Confirmed';
      if (apiLogEntry.details) {
        logEntry.updatedLocation = locationMapper.mapFromApiLocation(apiLogEntry.details);
      }
      break;
    case 'YARD_CHECK_CORRECTION':
      logEntry.type = 'Yard Check Correction';
      if (apiLogEntry.details) {
        logEntry.previousLocation = locationMapper.mapFromApiLocation(apiLogEntry.details.previousLocation);
        logEntry.updatedLocation = locationMapper.mapFromApiLocation(apiLogEntry.details.updatedLocation);
      }
      break;
    case 'YARD_CHECK_ADDITION':
      logEntry.type = 'Yard Check Addition';
      if (apiLogEntry.details) {
        logEntry.previousLocation = locationMapper.mapFromApiLocation(apiLogEntry.details);
      }
      break;
    case 'YARD_CHECK_EQUIPMENT_UPDATED':
      logEntry.type = 'Yard Check Equipment Updated';
      if (apiLogEntry.details) {
        logEntry.previousEquipment = equipmentMapper.mapFromApiEquipment(apiLogEntry.details.previousEquipment);
        logEntry.updatedEquipment = equipmentMapper.mapFromApiEquipment(apiLogEntry.details.updatedEquipment);
      }
      break;
    case 'EXCEEDED_EQUIPMENT_CAPACITY':
      logEntry.type = 'Location Exceeded Capacity';
      if (apiLogEntry.details) {
        logEntry.updatedLocation = locationMapper.mapFromApiLocation(apiLogEntry.details);
      }
      break;
    case 'EQUIPMENT_UPDATED':
      logEntry.type = 'Equipment Updated';
      if (apiLogEntry.details) {
        logEntry.previousEquipment = equipmentMapper.mapFromApiEquipment(apiLogEntry.details.previousEquipment);
        logEntry.updatedEquipment = equipmentMapper.mapFromApiEquipment(apiLogEntry.details.updatedEquipment);
      }
      break;
    case 'EQUIPMENT_LOCATION_UPDATED':
      logEntry.type = 'Equipment Location Updated';
      if (apiLogEntry.details) {
        logEntry.previousLocation = locationMapper.mapFromApiLocation(apiLogEntry.details.previousLocation);
        logEntry.updatedLocation = locationMapper.mapFromApiLocation(apiLogEntry.details.updatedLocation);
      }
      break;
    default:
      logEntry.type = apiLogEntry.type;
  }
  return logEntry;
}

function mapFromApiLogEntry(apiLogEntry) {
  const logEntry = {
    id: apiLogEntry.id,
    equipmentId: apiLogEntry.equipmentId,
    equipment: equipmentMapper.mapFromApiEquipment(apiLogEntry.equipment),
    by: apiLogEntry.by,
    date: apiLogEntry.date,
    formattedDate: formatDate(apiLogEntry.date),
    previousLocation: null,
    updatedLocation: null,
    previousEquipment: null,
    updatedEquipment: null,
    direction: null,
  };

  mapByType(logEntry, apiLogEntry);

  return logEntry;
}

function mapFromApiLogEntries(apiLogEntries) {
  const logEntries = [];

  if (apiLogEntries) {
    apiLogEntries.forEach((apiLogEntry) => {
      const logEntry = mapFromApiLogEntry(apiLogEntry);
      logEntries.push(logEntry);
    });
  }

  return logEntries;
}

function mapFromApiInaccuracy(apiInaccuracy) {
  const inaccuracy = {
    id: apiInaccuracy.id,
    equipment: equipmentMapper.mapFromApiEquipment(apiInaccuracy.equipment),
    by: apiInaccuracy.by,
    date: apiInaccuracy.date,
    formattedDate: formatDate(apiInaccuracy.date),
    gateInLogEntry: mapFromApiLogEntry(apiInaccuracy.gateInLogEntry),
    logHistory: mapFromApiLogEntries(apiInaccuracy.equipmentLogHistory),
    previousLocation: null,
    updatedLocation: null,
    previousEquipment: null,
    updatedEquipment: null,
    direction: null,
  };

  mapByType(inaccuracy, apiInaccuracy);

  return inaccuracy;
}

function mapFromApiInaccuracies(response) {
  const inaccuracies = [];
  const apiInaccuracies = response.Log;

  if (apiInaccuracies) {
    apiInaccuracies.forEach((apiInaccuracy) => {
      const inaccuracy = mapFromApiInaccuracy(apiInaccuracy);
      inaccuracies.push(inaccuracy);
    });
  }

  return inaccuracies;
}

export default {
  mapFromApiLogEntries,
  mapFromApiLogEntry,
  mapFromApiInaccuracies,
  mapFromApiInaccuracy,
};

import Vue from 'vue';
import checkMapper from '../mappers/checkMapper';
import equipmentMapper from '../mappers/equipmentMapper';
import locationMapper from '../mappers/locationMapper';

function getChecks() {
  return Vue.axios.get('check/history').then((result) => {
    let apiChecks = [];

    if (result.data) {
      apiChecks = result.data;
    }

    return checkMapper.mapFromApiChecks(apiChecks);
  });
}

function getActiveChecks() {
  return Vue.axios.get('check').then((result) => {
    let apiChecks = [];

    if (result.data) {
      apiChecks = result.data;
    }

    return checkMapper.mapFromApiChecks(apiChecks);
  });
}

function startCheck(date, startedBy) {
  const request = {
    StartTime: date,
    StartedBy: startedBy,
  };
  return Vue.axios.post('check/start', request).then((result) => result.data);
}

function stopCheck(check) {
  return Vue.axios.post('check/stop', check);
}

function addConfirmedEquipment(checkId, locationID, equipment, updatedBy) {
  const request = {
    checkId,
    locationEquipmentRequest: locationMapper.mapToLocationEquipmentRequest(locationID, equipment),
    updatedBy,
  };

  return Vue.axios.post('check/equipment/confirmed', request).then((result) => equipmentMapper.mapFromApiEquipment(result.data));
}

function removeConfirmedEquipment(checkId, locationID, equipment, updatedBy) {
  const request = {
    checkID: checkId,
    locationEquipmentRequest: locationMapper.mapToLocationEquipmentRequest(locationID, equipment),
    updatedBy,
  };

  return Vue.axios.delete('check/equipment/confirmed', { data: request });
}

function addLostEquipment(locationID, equipment, updatedBy) {
  const request = {
    locationEquipmentRequest: locationMapper.mapToLocationEquipmentRequest(locationID, equipment),
    by: updatedBy,
  };

  return Vue.axios.post('equipment/lost', request);
}

function removeLostEquipment(equipmentToBeMapped, updatedBy) {
  const request = {
    equipment: equipmentMapper.mapToApiEquipment(equipmentToBeMapped),
    by: updatedBy,

  };

  return Vue.axios.delete('equipment/lost', { data: request });
}

function addConfirmedLocation(checkID, location, updatedBy) {
  const request = {
    checkID,
    location,
    updatedBy,
  };

  return Vue.axios.post('check/location/confirmed', request);
}

function removeConfirmedLocation(checkID, location) {
  const request = {
    checkID,
    location,
  };

  return Vue.axios.delete('check/location/confirmed', { data: request });
}

export default {
  getChecks,
  getActiveChecks,
  startCheck,
  stopCheck,
  addConfirmedEquipment,
  removeConfirmedEquipment,
  addLostEquipment,
  removeLostEquipment,
  addConfirmedLocation,
  removeConfirmedLocation,
};

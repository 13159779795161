import _ from 'lodash';
import router from '../router/index';
import appointmentMapper from '../mappers/appointmentMapper';
import appointmentService from '../services/appointmentService';
import calendarEventMapper from '../mappers/calendarEventMapper';

const defaultState = {
  selectedAppointment: _.cloneDeep(appointmentMapper.defaultAppointment),
  appointments: [],
  calendarEvents: [],
  isLoading: false,
  showAppointmentEntry: false,
  showCancelConfirmation: false,
  eventFilter: '',
  availableTimes: [],
  startDateFilter: '',
  endDateFilter: '',
  focusedDateString: '',
  calendarType: 'day',
};

const state = defaultState;

const getters = {
  selectedAppointment(state) {
    return state.selectedAppointment;
  },
  appointments(state) {
    return state.appointments;
  },
  calendarEvents(state) {
    return state.calendarEvents;
  },
  isLoading(state) {
    return state.isLoading;
  },
  showAppointmentEntry(state) {
    return state.showAppointmentEntry;
  },
  showCancelConfirmation(state) {
    return state.showCancelConfirmation;
  },
  eventFilter(state) {
    return state.eventFilter;
  },
  availableTimes: (state, getters, rootState, rootGetters) => (date) => {
    const firstAvailableTime = rootGetters['config/appointmentFirstAvailableTime'];
    const lastAvailableTime = rootGetters['config/appointmentLastAvailableTime'];
    const appointmentIntervalInMinutes = rootGetters['config/appointmentIntervalInMinutes'];

    const startDate = new Date();
    const endDate = new Date();
    startDate.setHours(firstAvailableTime.hour);
    startDate.setMinutes(firstAvailableTime.minute);
    endDate.setHours(lastAvailableTime.hour);
    endDate.setMinutes(lastAvailableTime.minute);

    const currentDate = startDate;
    const dateArray = [];
    while (currentDate <= endDate) {
      dateArray.push(new Date(currentDate));
      currentDate.setMinutes(currentDate.getMinutes() + appointmentIntervalInMinutes);
    }

    const maxAppointmentsPerTimeSlot = rootGetters['config/maxAppointmentsPerTimeSlot'];
    const filteredAppointmentsByDate = state.appointments.filter((w) => w.startDate.toDateString() === date.toDateString());
    const currentAppointmentTimes = _.map(filteredAppointmentsByDate, 'startTime');
    const availableTimes = [];
    for (let i = 0; i < dateArray.length; i += 1) {
      const hour = dateArray[i].getHours();
      const minute = dateArray[i].getMinutes();
      const matchingAppointments = currentAppointmentTimes.filter((appt) => appt.hour === hour && appt.minute === minute);
      const isFull = matchingAppointments.length === maxAppointmentsPerTimeSlot;
      availableTimes.push({
        date: dateArray[i],
        isFull,
      });
    }

    return availableTimes;
  },
  startDateFilter() {
    return state.startDateFilter;
  },
  endDateFilter() {
    return state.endDateFilter;
  },
  filteredEvents(state, getters) {
    const events = getters.calendarEvents.filter((calendarEvent) => (calendarEvent.appointment.number && calendarEvent.appointment.number.toLowerCase().includes(state.eventFilter.toLowerCase()))
    || (calendarEvent.appointment.customer.name && calendarEvent.appointment.customer.name.toLowerCase().includes(state.eventFilter.toLowerCase())));

    return events.sort((a, b) => a.appointment.number.localeCompare(b.appointment.number));
  },
  focusedDateString() {
    return state.focusedDateString;
  },
  calendarType() {
    return state.calendarType;
  },
};

const actions = {
  async getAppointments({ commit, dispatch }) {
    commit('setIsLoading', true);
    const appointments = await appointmentService.getAppointments(state.startDateFilter, state.endDateFilter);
    commit('setAppointments', appointments);
    state.calendarEvents = [];
    _.forEach(appointments, (appointment) => {
      dispatch('addCalendarEvent', appointment);
    });
    commit('setIsLoading', false);
  },
  async getNextAvailableAppointmentNumber({ dispatch }) {
    try {
      const nextAvailableAppointmentNumber = await appointmentService.getNextAvailableAppointmentNumber();
      dispatch('setNumber', nextAvailableAppointmentNumber);
    } catch (error) {
      dispatch('alert/raiseError', error.message, { root: true });
    }
  },
  async addAppointment({ dispatch }, appointment) {
    const createdAppointment = await appointmentService.addAppointment(appointment);
    dispatch('appointmentAdded', createdAppointment);
  },
  addCalendarEvent({ commit }, appointment) {
    const calendarEvent = calendarEventMapper.mapToCalendarEvent(appointment);
    commit('addCalendarEvent', calendarEvent);
  },
  async updateAppointment({ dispatch }, request) {
    const updatedAppointment = await appointmentService.updateAppointment(request.appointment, request.updatedBy);
    dispatch('appointmentUpdated', updatedAppointment);
  },
  async cancelAppointment({ dispatch }, request) {
    await appointmentService.cancelAppointment(request.appointment, request.deletedBy);
    dispatch('appointmentCanceled', request.appointment.id);
  },
  appointmentAdded({ commit, getters }, appointment) {
    const appointmentExists = getters.appointments.some((a) => a.id === appointment.id);
    if (!appointmentExists) {
      commit('addAppointment', appointment);
      const calendarEvent = calendarEventMapper.mapToCalendarEvent(appointment);
      commit('addCalendarEvent', calendarEvent);
    }
  },
  appointmentUpdated({ commit }, appointment) {
    commit('updateAppointment', appointment);
    const calendarEvent = calendarEventMapper.mapToCalendarEvent(appointment);
    commit('updateCalendarEvent', calendarEvent);
  },
  appointmentCanceled({ commit }, appointmentId) {
    commit('cancelAppointment', appointmentId);
    commit('cancelCalendarEvent', appointmentId);
  },
  resetState({ commit, dispatch }) {
    commit('resetState');
    if (router.currentRoute.name === 'AppointmentView') {
      dispatch('getAppointments');
    }
  },
  setShowAppointmentEntry({ commit }, appointmentEntry) {
    commit('setShowAppointmentEntry', appointmentEntry);
  },
  setShowCancelConfirmation({ commit }, showCancelConfirmation) {
    commit('setShowCancelConfirmation', showCancelConfirmation);
  },
  setEventFilter({ commit }, eventFilter) {
    commit('setEventFilter', eventFilter);
  },
  setNumber({ commit }, number) {
    commit('setNumber', number);
  },
  setBOL({ commit }, BOL) {
    commit('setBOL', BOL);
  },
  setBrokerageReferenceNumber({ commit }, brokerageReferenceNumber) {
    commit('setBrokerageReferenceNumber', brokerageReferenceNumber);
  },
  setDeliveryOrderNumber({ commit }, deliveryOrderNumber) {
    commit('setDeliveryOrderNumber', deliveryOrderNumber);
  },
  setPickupNumber({ commit }, pickupNumber) {
    commit('setPickupNumber', pickupNumber);
  },
  setPONumber({ commit }, poNumber) {
    commit('setPONumber', poNumber);
  },
  setReferenceNumber({ commit }, referenceNumber) {
    commit('setReferenceNumber', referenceNumber);
  },
  setSONumber({ commit }, SONumber) {
    commit('setSONumber', SONumber);
  },
  setTMSReferenceNumber({ commit }, TMSNumber) {
    commit('setTMSReferenceNumber', TMSNumber);
  },
  setSelectedAppointment({ commit }, appointment) {
    commit('setSelectedAppointment', appointment);
  },
  setStartDate({ commit }, startDate) {
    commit('setStartDate', startDate);
  },
  setStartTime({ commit }, startTime) {
    commit('setStartTime', startTime);
  },
  setEndTime({ commit }, endTime) {
    commit('setEndTime', endTime);
  },
  setDirection({ commit }, direction) {
    commit('setDirection', direction);
  },
  setActionType({ commit }, actionType) {
    commit('setActionType', actionType);
  },
  setTransportType({ commit }, transportType) {
    commit('setTransportType', transportType);
  },
  setCustomerName({ commit }, customerName) {
    commit('setCustomerName', customerName);
  },
  setCarrierName({ commit }, carrierName) {
    commit('setCarrierName', carrierName);
  },
  setComments({ commit }, comments) {
    commit('setComments', comments);
  },
  setEquipmentType({ commit }, equipmentType) {
    commit('setEquipmentType', equipmentType);
  },
  setEquipmentNumber({ commit }, equipmentNumber) {
    commit('setEquipmentNumber', equipmentNumber);
  },
  setEquipmentSize({ commit }, equipmentSize) {
    commit('setEquipmentSize', equipmentSize);
  },
  setEquipmentState({ commit }, equipmentState) {
    commit('setEquipmentState', equipmentState);
  },
  setTrailerCondition({ commit }, trailerCondition) {
    commit('setTrailerCondition', trailerCondition);
  },
  setIsSealIntact({ commit }, val) {
    commit('setIsSealIntact', val);
  },
  setSealNumber({ commit }, sealNumber) {
    commit('setSealNumber', sealNumber);
  },
  setLocationId({ commit }, locationId) {
    commit('setLocationId', locationId);
  },
  setLocationDisplayName({ commit }, displayName) {
    commit('setLocationDisplayName', displayName);
  },
  setPriority({ commit }, priority) {
    commit('setPriority', priority);
  },
  setDriverName({ commit }, driverName) {
    commit('setDriverName', driverName);
  },
  setDriverPhone({ commit }, driverPhone) {
    commit('setDriverPhone', driverPhone);
  },
  setDriverService({ commit }, driverService) {
    commit('setDriverService', driverService);
  },
  setStartDateFilter({ commit }, value) {
    commit('setStartDateFilter', value);
  },
  setEndDateFilter({ commit }, value) {
    commit('setEndDateFilter', value);
  },
  setFocusedDateString({ commit }, value) {
    commit('setFocusedDateString', value);
  },
  setCalendarType({ commit }, value) {
    commit('setCalendarType', value);
  },
  setAppointmentCreatedBy({ commit }, value) {
    commit('setAppointmentCreatedBy', value);
  },
};

const mutations = {
  setSelectedAppointment(state, appointment) {
    state.selectedAppointment = appointment;
  },
  setAppointments(state, appointments) {
    state.appointments = appointments;
  },
  setIsLoading(state, value) {
    state.isLoading = value;
  },
  setShowAppointmentEntry(state, value) {
    state.showAppointmentEntry = value;
  },
  setShowCancelConfirmation(state, value) {
    state.showCancelConfirmation = value;
  },
  setEventFilter(state, value) {
    state.eventFilter = value;
  },
  setNumber(state, number) {
    state.selectedAppointment.number = number;
  },
  setBOL(state, BOL) {
    state.selectedAppointment.BOL = BOL;
  },
  setBrokerageReferenceNumber(state, brokerageReferenceNumber) {
    state.selectedAppointment.brokerageReferenceNumber = brokerageReferenceNumber;
  },
  setDeliveryOrderNumber(state, deliveryOrderNumber) {
    state.selectedAppointment.deliveryOrderNumber = deliveryOrderNumber;
  },
  setPickupNumber(state, pickupNumber) {
    state.selectedAppointment.pickupNumber = pickupNumber;
  },
  setPONumber(state, poNumber) {
    state.selectedAppointment.PONumber = poNumber;
  },
  setReferenceNumber(state, referenceNumber) {
    state.selectedAppointment.referenceNumber = referenceNumber;
  },
  setSONumber(state, SONumber) {
    state.selectedAppointment.SONumber = SONumber;
  },
  setTMSReferenceNumber(state, TMSReferenceNumber) {
    state.selectedAppointment.TMSReferenceNumber = TMSReferenceNumber;
  },
  setDirection(state, direction) {
    state.selectedAppointment.direction = direction;
  },
  setCustomerName(state, customerName) {
    state.selectedAppointment.customer.name = customerName;
  },
  setCarrierName(state, carrierName) {
    state.selectedAppointment.equipment.carrier.name = carrierName;
  },
  setStartDate(state, date) {
    state.selectedAppointment.startDate = date;
  },
  setStartTime(state, startTime) {
    state.selectedAppointment.startTime = startTime;
  },
  setEndTime(state, endTime) {
    state.selectedAppointment.endTime = endTime;
  },
  setActionType(state, actionType) {
    state.selectedAppointment.actionType = actionType;
  },
  setTransportType(state, transportType) {
    state.selectedAppointment.transportType = transportType;
  },
  setComments(state, comments) {
    state.selectedAppointment.comments = comments;
  },
  setEquipmentType(state, equipmentType) {
    state.selectedAppointment.equipment.type = equipmentType;
  },
  setEquipmentNumber(state, equipmentNumber) {
    state.selectedAppointment.equipment.number = equipmentNumber;
  },
  setEquipmentSize(state, equipmentSize) {
    state.selectedAppointment.equipment.size = equipmentSize;
  },
  setEquipmentState(state, equipmentState) {
    state.selectedAppointment.equipment.state = equipmentState;
  },
  setTrailerCondition(state, trailerCondition) {
    state.selectedAppointment.trailerCondition = trailerCondition;
  },
  setIsSealIntact(state, val) {
    state.selectedAppointment.isSealIntact = val;
  },
  setSealNumber(state, sealNumber) {
    state.selectedAppointment.sealNumber = sealNumber;
  },
  setLocationDisplayName(state, displayName) {
    state.selectedAppointment.location.displayName = displayName;
  },
  setLocationGroups(state, groups) {
    state.selectedAppointment.location.groups = groups;
  },
  setLocationId(state, id) {
    state.selectedAppointment.location.id = id;
  },
  setPriority(state, priority) {
    state.selectedAppointment.isPriority = priority;
  },
  setDriverName(state, driverName) {
    state.selectedAppointment.driver.name = driverName;
  },
  setDriverPhone(state, driverPhone) {
    state.selectedAppointment.driver.phone = driverPhone;
  },
  setDriverService(state, driverService) {
    state.selectedAppointment.driver.service = driverService;
  },
  addAppointment(state, appointment) {
    state.appointments.push(appointment);
  },
  addCalendarEvent(state, calendarEvent) {
    state.calendarEvents.push(calendarEvent);
  },
  updateAppointment(state, appointment) {
    if (state.appointments.length > 0) {
      const item = _.find(state.appointments, (a) => a.id === appointment.id);
      _.remove(state.appointments, item);
      state.appointments.push(appointment);
    }
  },
  updateCalendarEvent(state, calendarEvent) {
    if (state.calendarEvents.length > 0) {
      const item = _.find(state.calendarEvents, (o) => o.appointment.id === calendarEvent.appointment.id);
      _.remove(state.calendarEvents, item);
      state.calendarEvents.push(calendarEvent);
    }
  },
  cancelAppointment(state, appointmentId) {
    if (state.appointments.length > 0) {
      const item = _.find(state.appointments, (appointment) => appointment.id === appointmentId);
      if (item !== undefined) {
        _.remove(state.appointments, item);
      }
    }
  },
  cancelCalendarEvent(state, appointmentId) {
    const index = state.calendarEvents.findIndex((event) => event.appointment.id === appointmentId);
    if (index !== -1) {
      state.calendarEvents.splice(index, 1);
    }
  },
  resetState(state) {
    Object.assign(state, defaultState);
  },
  setStartDateFilter(state, value) {
    state.startDateFilter = new Date(value);
  },
  setEndDateFilter(state, value) {
    state.endDateFilter = new Date(value);
  },
  setFocusedDateString(state, value) {
    state.focusedDateString = value;
  },
  setCalendarType(state, value) {
    state.calendarType = value;
  },
  setAppointmentCreatedBy(state, value) {
    state.selectedAppointment.createdBy = value;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

import Vue from 'vue';
import Vuex from 'vuex';
import sidenav from './sidenavModule';
import account from './accountModule';
import alert from './alertModule';
import equipmentLog from './equipmentLogModule';
import check from './checkModule';
import location from './locationModule';
import appointment from './appointmentModule';
import gate from './gateModule';
import expectedGateActivity from './expectedGateActivityModule';
import move from './moveModule';
import admin from './adminModule';
import config from './configModule';
import equipment from './equipmentModule';
import verifyYardCheck from './verifyYardCheckModule';
import lostYardCheck from './lostYardCheckModule';
import yard from './yardModule';
import webSocket from './websocketModule';
import checkView from './checkViewModule';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
  },
  modules: {
    admin,
    account,
    alert,
    equipmentLog,
    check,
    location,
    gate,
    expectedGateActivity,
    appointment,
    move,
    config,
    sidenav,
    equipment,
    verifyYardCheck,
    lostYardCheck,
    yard,
    webSocket,
    checkView,
  },
  mutations: {
  },
  actions: {
    resetStoreState() {
      this.dispatch('equipmentLog/resetState');
      this.dispatch('check/resetState');
      this.dispatch('location/resetState');
      this.dispatch('appointment/resetState');
      this.dispatch('admin/resetState');
      this.dispatch('move/resetState');
    },
  },
});

export default store;

import dateHelper from '../helpers/dateHelper';

function mapToApiAppointment(appointment) {
  const apiAppointment = appointment;
  const startDate = new Date(appointment.startDate);
  const endDate = new Date(appointment.startDate);
  startDate.setHours(0, 0, 0, 0);

  if (appointment.startTime.hour != null) {
    startDate.setHours(appointment.startTime.hour);
    startDate.setMinutes(appointment.startTime.minute);
  }

  apiAppointment.startDate = dateHelper.convertToUTC(startDate);

  if (appointment.endTime && appointment.endTime.hour != null) {
    endDate.setHours(appointment.endTime.hour);
    endDate.setMinutes(appointment.endTime.minute);
    apiAppointment.endDate = dateHelper.convertToUTC(endDate);
  }

  if (appointment.location && appointment.location.id) {
    apiAppointment.locationID = appointment.location.id;
  }

  return apiAppointment;
}

function mapFromApiAppointments(apiAppointments) {
  const appointments = [];

  apiAppointments.forEach((apiAppointment) => {
    const appointment = this.mapFromApiAppointment(apiAppointment);
    appointments.push(appointment);
  });

  return appointments;
}

function mapFromApiAppointment(apiAppointment) {
  const appointment = apiAppointment;
  appointment.endTime = {};

  const startDate = new Date(dateHelper.getLocaleDateStringFromUTC(apiAppointment.startDate));
  appointment.startDate = startDate;
  appointment.startTime = {
    hour: startDate.getHours(),
    minute: startDate.getMinutes(),
  };
  if (apiAppointment.endDate) {
    const endDate = new Date(dateHelper.getLocaleDateStringFromUTC(apiAppointment.endDate));
    appointment.endDate = endDate;
    appointment.endTime = {
      hour: endDate.getHours(),
      minute: endDate.getMinutes(),
    };
  }
  return appointment;
}

export default {
  defaultAppointment: {
    startDate: new Date(),
    startTime: {
      hour: 8,
      minute: 0,
    },
    endTime: {
      hour: 8,
      minute: 30,
    },
    actionType: null,
    transportType: null,
    equipment: {
      number: null,
      type: null,
      state: null,
      size: null,
      carrier: {
        name: null,
      },
    },
    location: {
      id: null,
      displayName: null,
    },
    driver: {
      name: null,
      phone: null,
      service: null,
    },
    customer: {
      name: null,
    },
    BOL: null,
    brokerageReferenceNumber: null,
    deliveryOrderNumber: null,
    pickupNumber: null,
    PONumber: null,
    referenceNumber: null,
    SONumber: null,
    TMSReferenceNumber: null,
    comments: null,
    isSealIntact: null,
    isPriority: null,
    sealNumber: null,
    trailerCondition: null,
    direction: null,
    createdBy: '',
  },
  mapToApiAppointment,
  mapFromApiAppointment,
  mapFromApiAppointments,
};

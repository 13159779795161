const EventTypes = {
  MOVE: 'MOVE',
  MOVE_DELETE: 'MOVE_DELETE',
  LOCATION_EQUIPMENT: 'LOCATION_EQUIPMENT',
  LOCATION_EQUIPMENT_DELETE: 'LOCATION_EQUIPMENT_DELETE',
  YARD_CHECK: 'YARD_CHECK',
  YARD_CHECK_DELETE: 'YARD_CHECK_DELETE',
  YARD_CHECK_CONFIRMED_EQUIPMENT: 'YARD_CHECK_CONFIRMED_EQUIPMENT',
  YARD_CHECK_CONFIRMED_EQUIPMENT_DELETE: 'YARD_CHECK_CONFIRMED_EQUIPMENT_DELETE',
  YARD_CHECKS_CONFIRMED_EQUIPMENT: 'YARD_CHECKS_CONFIRMED_EQUIPMENT',
  YARD_CHECKS_CONFIRMED_EQUIPMENT_DELETE: 'YARD_CHECKS_CONFIRMED_EQUIPMENT_DELETE',
  YARD_CHECK_CONFIRMED_LOCATION: 'YARD_CHECK_CONFIRMED_LOCATION',
  YARD_CHECK_CONFIRMED_LOCATION_DELETE: 'YARD_CHECK_CONFIRMED_LOCATION_DELETE',
  YARD_CHECKS_CONFIRMED_LOCATION: 'YARD_CHECKS_CONFIRMED_LOCATION',
  YARD_CHECKS_CONFIRMED_LOCATION_DELETE: 'YARD_CHECKS_CONFIRMED_LOCATION_DELETE',
  LOST_EQUIPMENT: 'LOST_EQUIPMENT',
  LOST_EQUIPMENT_DELETE: 'LOST_EQUIPMENT_DELETE',
  EQUIPMENT: 'EQUIPMENT',
  USER_UPSERT: 'USER_UPSERT',
  APPOINTMENT_CREATED: 'APPOINTMENT_CREATED',
  APPOINTMENT_UPDATED: 'APPOINTMENT_UPDATED',
  APPOINTMENT_DELETED: 'APPOINTMENT_DELETED',
};

export default EventTypes;

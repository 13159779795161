<template>
      <v-navigation-drawer v-model="isVisible" class="primary" app>
        <v-list-item style="color: white">
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              Navigation
              <v-icon
                style="color: white"
                class="float-right"
                @click="setIsSideNavVisible(false)"
                >keyboard_arrow_left</v-icon
              >
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list>
          <div v-for="(item, i) in visibleRoutes" :key="i">
            <v-list-item-group
              active-class="white--text text--accent-4"
              v-if="!item.subLinks"
            >
              <v-list-item :to="{ name: item.routeName }" selectable>
                <v-list-item-icon>
                  <template v-if="item.isMaterialIcon">
                    <v-icon v-text="item.icon" :color="item.color"></v-icon>
                  </template>
                  <template v-else>
                    <v-img :src="item.icon"></v-img>
                  </template>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="item.name"
                    style="color: white"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
            <v-list-group v-else :key="item.name" no-action :value="false">
              <template v-slot:activator>
                <v-list-item class="pl-0">
                  <v-list-item-icon>
                    <template v-if="item.isMaterialIcon">
                      <v-icon v-text="item.icon" :color="item.color"></v-icon>
                    </template>
                    <template v-else>
                      <v-img :src="item.icon" />
                    </template>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="item.name"
                      style="color: white"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>

              <v-list-item
                v-for="sublink in item.subLinks"
                active-class="white--text text--accent-4"
                :to="{ name: sublink.routeName }"
                selectable
                :key="sublink.name"
              >
                <v-list-item-icon>
                  <v-icon v-text="sublink.icon" :color="sublink.color"></v-icon>
                </v-list-item-icon>
                <v-list-item-title style="color: white">
                    {{sublink.name}}
                </v-list-item-title>
              </v-list-item>
            </v-list-group>
          </div>
        </v-list>
      </v-navigation-drawer>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';
import router from '../../router/index';
import routeLogic from '../../logic/routeLogic';
import truckIcon from '../../assets/truck.png';

export default {
  data: () => ({
    options: [
      {
        name: 'Gate',
        routeName: 'Gate',
        icon: 'home',
        color: 'white',
        isVisible: true,
        isMaterialIcon: true,
      },
      {
        name: 'Yard',
        routeName: 'YardView',
        icon: 'dashboard',
        color: 'white',
        isVisible: true,
        isMaterialIcon: true,
      },
      {
        name: 'Log',
        icon: 'view_list',
        color: 'white',
        routeName: 'Log',
        isVisible: true,
        isMaterialIcon: true,
        subLinks: [
          {
            name: 'History',
            routeName: 'EquipmentLogView',
            icon: 'assignment',
            color: 'white',
          },
          {
            name: 'Search',
            routeName: 'EquipmentLogSearchView',
            icon: 'search',
            color: 'white',
          },
        ],
      },
      {
        name: 'Check',
        icon: 'fact_check',
        routeName: 'YardCheckView',
        color: 'white',
        isVisible: true,
        isMaterialIcon: true,
      },
      {
        name: 'Jockey',
        icon: truckIcon,
        routeName: 'JockeyView',
        color: 'white',
        isVisible: true,
        isMaterialIcon: false,
      },
      {
        name: 'Appointment',
        icon: 'event',
        routeName: 'AppointmentView',
        color: 'white',
        isVisible: true,
        isMaterialIcon: true,
      },
      {
        name: 'Admin',
        icon: 'badge',
        color: 'white',
        routeName: 'Admin',
        isVisible: false,
        isMaterialIcon: true,
        subLinks: [
          {
            name: 'Manage Users',
            routeName: 'ManageUsersView',
            icon: 'people',
            color: 'white',
          },
          {
            name: 'Yard Accuracy',
            routeName: 'YardAccuracyReportView',
            icon: 'query_stats',
            color: 'white',
          },
          {
            name: 'Yard Check History',
            routeName: 'YardCheckHistoryView',
            icon: 'view_list',
            color: 'white',
          },
        ],
      },
      {
        name: 'Developer',
        icon: 'code',
        color: 'white',
        routeName: 'Developer',
        isVisible: false,
        isMaterialIcon: true,
        subLinks: [
          {
            name: 'Manage Yards',
            icon: 'build',
            routeName: 'ManageYardsView',
            color: 'white',
            isVisible: false,
            isMaterialIcon: true,
          },
        ],
      },
      {
        name: 'Overview',
        icon: 'visibility',
        routeName: 'OverviewView',
        color: 'white',
        isVisible: true,
        isMaterialIcon: true,
      },
    ],
  }),
  watch: {
    isLoggedIn(isLoggedIn) {
      if (isLoggedIn) {
        this.setOptionVisibility();
      }
    },
  },
  computed: {
    ...mapGetters('account', ['isLoggedIn']),
    visibleRoutes() {
      const options = _.filter(this.options, (option) => option.isVisible);
      // HACK: Remove when overview is accessible for more than 5816
      const yardId5816 = '5816';
      if (this.selectedYard && this.selectedYard.yardId !== yardId5816) {
        const overview = options.find((w) => w.name === 'Overview');
        _.remove(options, overview);
      }
      return options;
    },
    isVisible: {
      get() {
        return this.isSideNavVisible();
      },
      set(val) {
        return this.setIsSideNavVisible(val);
      },
    },
    ...mapGetters('account', ['selectedYard']),
  },
  methods: {
    ...mapActions('sidenav', ['setIsSideNavVisible']),
    ...mapGetters('sidenav', ['isSideNavVisible']),
    setOptionVisibility() {
      _.each(this.options, (option) => {
        const routerRoute = _.find(
          router.options.routes,
          (route) => route.name === option.routeName,
        );
        if (routerRoute && routerRoute.meta.validRoles) {
          const user = JSON.parse(localStorage.getItem('user'));
          if (user) {
            option.isVisible = routeLogic.hasAccess(
              user.roles,
              routerRoute.meta.validRoles,
            );
          }
        }
      });
    },
  },
  created() {
    this.setOptionVisibility();
  },
};
</script>

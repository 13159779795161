import Vue from 'vue';
import moveMapper from '../mappers/moveMapper';

function getPendingMoves() {
  return Vue.axios.get('move?type=pending').then((result) => {
    let apiMoves = [];

    if (result.data) {
      apiMoves = result.data;
    }

    return moveMapper.mapFromApiMoves(apiMoves);
  });
}

function getCompletedMoves(date) {
  return Vue.axios.get(`move?type=completed&date=${date}`).then((result) => {
    const completedMoves = moveMapper.mapFromApiMoves(result.data);
    return completedMoves;
  });
}

async function start(move) {
  const request = {
    moveId: move.id,
    startDate: move.startDate,
    assignedTo: move.assignedTo,
  };
  return Vue.axios.put('move/start', request);
}

function updateMove(request) {
  return Vue.axios.put('move/update', request).then((result) => {
    const move = moveMapper.mapFromApiMove(result.data);
    return move;
  });
}

function stop(move) {
  const request = {
    move,
    completedDate: move.completedDate,
    completedBy: move.completedBy,
  };
  return Vue.axios.put('move/stop', request);
}

function removeMove(move) {
  const request = {
    moveId: move.id,
    deletedBy: move.deletedBy,
  };
  return Vue.axios.delete('move', { data: request });
}

function saveMove(request) {
  return Vue.axios.post('move', request).then((result) => {
    const move = moveMapper.mapFromApiMove(result.data);
    return move;
  });
}

export default {
  getPendingMoves,
  getCompletedMoves,
  start,
  updateMove,
  stop,
  removeMove,
  saveMove,
};

const GOOD = 'Good';
const DAMAGED_WALL = 'Damaged Wall';
const DAMAGED_DOOR = 'Damaged Door';
const DAMAGED_CIELING = 'Damaged Cieling';
const DAMAGED_TIRE = 'Damaged Tire';
const DAMAGED_FLOOR = 'Damaged Floor';
const DAMAGED_HINGE = 'Damaged Hinge';
const LIGHTS = 'Lights';
const LANDING_GEAR = 'Landing Gear';
const BRAKES = 'Brakes';
const CTPAT_FAILED = 'CTPAT Failed';

export default {
  GOOD,
  DAMAGED_CIELING,
  DAMAGED_WALL,
  DAMAGED_DOOR,
  DAMAGED_TIRE,
  DAMAGED_HINGE,
  DAMAGED_FLOOR,
  LIGHTS,
  LANDING_GEAR,
  BRAKES,
  CTPAT_FAILED,
};

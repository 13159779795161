const state = {
  showDialog: false,
};

const mutations = {
  setShowDialog(state, value) {
    state.showDialog = value;
  },
};

const actions = {
  setShowDialog({ commit }, value) {
    commit('setShowDialog', value);
  },
};

const getters = {
  showDialog: (state) => state.showDialog,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

import direction from '../constants/direction';
import colors from '../constants/colors';

export default {
  getEventColor(directionType) {
    switch (directionType) {
      case direction.IN:
        return colors.INBOUND;
      case direction.OUT:
        return colors.OUTBOUND;
      default:
        return colors.AVAILABLE;
    }
  },
};

import Vue from 'vue';
import gateEntryMapper from '../mappers/gateEntryMapper';

function processGateIn(gateEntry) {
  gateEntry.location.equipment.arrivalDate = new Date().toISOString();
  const entry = gateEntryMapper.mapToApiGateEntry(gateEntry);
  return Vue.axios.post('gate/in', entry).then((result) => gateEntryMapper.mapFromGateInResponse(result.data)).catch(() => null);
}

function processGateOut(gateEntry) {
  const entry = gateEntryMapper.mapToApiGateEntry(gateEntry);
  return Vue.axios.post('gate/out', entry).then((result) => gateEntryMapper.mapFromGateOutResponse(result.data)).catch((error) => { throw error.response.data; });
}

export default {
  processGateIn,
  processGateOut,
};

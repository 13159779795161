import equipmentMapper from './equipmentMapper';

function mapFromApiLocations(apiLocations) {
  const locations = [];

  apiLocations.forEach((apiLocation) => {
    const location = this.mapFromApiLocation(apiLocation);
    locations.push(location);
  });

  return locations;
}

function mapFromApiLocation(apiLocation) {
  const location = {
    id: apiLocation.id,
    type: apiLocation.type,
    displayName: apiLocation.displayName,
    groups: apiLocation.groups ? apiLocation.groups : [],
    hasMoveFrom: false,
    hasMoveTo: false,
    status: '',
    equipment: [],
    equipmentIDs: [],
    equipmentCapacity: apiLocation.equipmentCapacity,
  };

  if (apiLocation.equipmentIDs) {
    location.equipmentIDs = apiLocation.equipmentIDs;
    location.equipment = equipmentMapper.mapFromApiEquipments(apiLocation.equipment);
  }

  return location;
}

function mapToApiLocation(location) {
  const apiLocation = {
    id: location.id,
    type: location.type,
    displayName: location.displayName,
    groups: location.groups,
  };

  if (location.equipment) {
    apiLocation.equipment = equipmentMapper.mapToApiEquipment(location.equipment);
  }

  return location;
}

function mapToLocationEquipmentRequest(locationID, equipment) {
  const locationEquipmentRequest = {
    equipment: equipmentMapper.mapFromApiEquipment(equipment),
    locationID,
  };

  return locationEquipmentRequest;
}

function mapFromApiLocationEquipmentRequest(locationEquipmentRequest) {
  const mappedRequest = {
    equipment: equipmentMapper.mapFromApiEquipment(locationEquipmentRequest.equipment),
    locationID: locationEquipmentRequest.locationID,
  };

  return mappedRequest;
}

const defaultLocation = {
  id: '',
  displayName: '',
  type: '',
  groups: [],
  equipmentIDs: [],
  equipment: [],
  maxEquipmentCapacity: 1,
};

export default {
  defaultLocation,
  mapFromApiLocations,
  mapFromApiLocation,
  mapToApiLocation,
  mapToLocationEquipmentRequest,
  mapFromApiLocationEquipmentRequest,
};

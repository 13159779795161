import _ from 'lodash';

export default {
  name: 'routeLogic',
  hasAccess(userRoles, validRolesForRoute) {
    let isAuthorized = false;
    for (let i = 0; i < userRoles.length; i += 1) {
      isAuthorized = _.find(validRolesForRoute, (validRole) => validRole === userRoles[i]);
      if (isAuthorized) {
        break;
      }
    }

    return isAuthorized;
  },
};

import customerMapper from './customerMapper';
import carrierMapper from './carrierMapper';

function mapAbbreviation(apiEquipment) {
  switch (apiEquipment.type) {
    case 'Container':
      return 'C';
    case 'Chassis':
      return 'Ch';
    case 'Van':
      return 'V';
    default:
      return '';
  }
}

function formatDate(date) {
  const d = new Date(date);
  return d.toLocaleString('en-US', { timeZone: 'America/New_York' });
}

function mapFromApiEquipment(apiEquipment) {
  const equipment = {
    id: apiEquipment.id,
    type: apiEquipment.type,
    size: apiEquipment.size,
    state: apiEquipment.state,
    number: apiEquipment.number,
    carrier: {},
    arrivalDate: apiEquipment.arrivalDate,
    formattedDate: formatDate(apiEquipment.arrivalDate),
    abbreviation: mapAbbreviation(apiEquipment),
    bookingNumber: apiEquipment.bookingNumber,
    customer: {},
    status: '',
    hasPendingMove: false,
  };

  if (apiEquipment.carrier) {
    equipment.carrier = carrierMapper.mapFromApiCarrier(apiEquipment.carrier);
  }

  if (apiEquipment.customer) {
    equipment.customer = customerMapper.mapFromApiCustomer(apiEquipment.customer);
  }

  return equipment;
}

function mapFromApiEquipments(apiEquipments) {
  const equipmentList = [];

  if (apiEquipments) {
    apiEquipments.forEach((apiEquipment) => {
      const equipment = mapFromApiEquipment(apiEquipment);
      equipmentList.push(equipment);
    });
  }

  return equipmentList;
}

function mapFromApiLostEquipments(apiLostEquipments) {
  const lostEquipmentList = [];

  if (apiLostEquipments) {
    apiLostEquipments.forEach((apiLostEquipment) => {
      const { insertedDate } = apiLostEquipment;
      const equipment = mapFromApiEquipment(apiLostEquipment.equipment);
      const lostEquipment = {
        insertedDate: formatDate(insertedDate),
        equipment,
      };
      lostEquipmentList.push(lostEquipment);
    });
  }

  return lostEquipmentList;
}

function mapToApiEquipment(equipment) {
  const apiEquipment = {
    type: equipment.type,
    size: equipment.size,
    state: equipment.state,
    number: equipment.number,
    arrivalDate: equipment.arrivalDate,
    bookingNumber: equipment.bookingNumber,
  };

  if (equipment.id) {
    apiEquipment.id = equipment.id;
  }

  if (equipment.carrier) {
    apiEquipment.carrier = carrierMapper.mapToApiCarrier(equipment.carrier);
  }

  if (equipment.customer) {
    apiEquipment.customer = customerMapper.mapToApiCustomer(equipment.customer);
  }

  return apiEquipment;
}

const defaultEquipment = {
  id: '',
  size: '',
  number: '',
  state: '',
  type: '',
  carrier: {
    id: '',
    scac: '',
    name: '',
  },
  arrivalDate: null,
  customer: {
    id: '',
    name: '',
  },
  bookingNumber: '',
  status: '',
  hasPendingMove: false,
};

function mapLocationsToEquipmentWithLocation(locations) {
  const mappedEquipment = [];
  for (let i = 0; i < locations.length; i += 1) {
    for (let j = 0; j < locations[i].equipment.length; j += 1) {
      const equipment = this.mapToEquipmentWithLocation(locations[i], locations[i].equipment[j]);
      mappedEquipment.push(equipment);
    }
  }
  return mappedEquipment;
}

function mapToEquipmentWithLocation(location, equipment) {
  const mappedEquipment = equipment;
  mappedEquipment.location = {
    id: location.id,
    type: location.type,
    displayName: location.displayName,
    groups: location.groups,
    hasMoveFrom: location.hasMoveFrom,
    hasMoveTo: location.hasMoveTo,
  };
  return mappedEquipment;
}

export default {
  defaultEquipment,
  formatDate,
  mapFromApiEquipment,
  mapFromApiEquipments,
  mapFromApiLostEquipments,
  mapToApiEquipment,
  mapToEquipmentWithLocation,
  mapLocationsToEquipmentWithLocation,
};

<template>
  <div id="app">
    <v-app>
      <sidenav></sidenav>
      <topbar></topbar>
      <v-main>
        <v-container fluid>
          <alert />
          <router-view></router-view>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import alert from './components/alert.vue';
import sidenav from './components/app/sidenav.vue';
import topbar from './components/app/topbar.vue';

export default {
  computed: {
    ...mapGetters('account', ['selectedYard', 'isLoggedIn', 'user']),
  },
  watch: {
    isLoggedIn(isLoggedIn) {
      if (!isLoggedIn) {
        this.setIsSideNavVisible(false);
        this.disconnectFromUsersWebSocket();
        this.disconnectFromYardWebSocket();
      } else {
        this.connectToUsersWebSocket();
        this.connectToYardWebSocket(this.selectedYard.yardId);
      }
    },
    selectedYard(newYardSelection, oldYardSelection) {
      if (this.isLoggedIn) {
        if (newYardSelection !== null && oldYardSelection !== null && oldYardSelection.yardId !== newYardSelection.yardId) {
          this.disconnectFromYardWebSocket();
          this.connectToYardWebSocket(newYardSelection.yardId);
          this.getLocations();
          // HACK: Remove when overview is accessible for more than 5816
          const Yard5816ID = '5816';
          const OverviewRouteName = 'OverviewView';
          const GateRoute = '/gate';
          if (newYardSelection.yardId !== Yard5816ID && this.$route.name === OverviewRouteName) {
            this.$router.push(GateRoute);
          }

          const today = new Date();
          today.setUTCHours(0, 0, 0, 0);
          const yesterday = new Date(today);
          yesterday.setDate(yesterday.getDate() - 1);
          this.setStartDateFilter(yesterday.toISOString());
          this.setEndDateFilter(yesterday.toISOString());
          this.getInaccuracies();
        }
      }
    },
  },
  components: { alert, sidenav, topbar },
  methods: {
    ...mapActions('sidenav', ['setIsSideNavVisible']),
    ...mapActions('location', ['getLocations']),
    ...mapActions('webSocket', ['connectToUsersWebSocket', 'connectToYardWebSocket', 'disconnectFromUsersWebSocket', 'disconnectFromYardWebSocket']),
    ...mapActions('equipmentLog', ['setStartDateFilter', 'setEndDateFilter', 'getInaccuracies']),
  },
  created() {
    if (this.isLoggedIn) {
      this.connectToUsersWebSocket();
      this.connectToYardWebSocket(this.selectedYard.yardId);
    }
  },
  beforeDestroy() {
    this.disconnectFromUsersWebSocket();
    this.disconnectFromYardWebSocket();
  },
};
</script>

const ACTIVE = 'ACTIVE';
const INACTIVE = 'INACTIVE';
const ALL_STATUSES = [
  ACTIVE,
  INACTIVE,
];

export default {
  ACTIVE,
  INACTIVE,
  ALL_STATUSES,
};

import Vue from 'vue';
import locationMapper from '../mappers/locationMapper';

async function getLocationsWithEquipment() {
  const response = await Vue.axios.get('location/equipment');
  const apiLocations = response.data;
  const locations = locationMapper.mapFromApiLocations(apiLocations);
  return locations;
}

function updateEquipment(locationID, equipment, yardCheckId, updatedBy) {
  const request = {
    checkID: yardCheckId,
    locationEquipmentRequest: {
      locationID,
      equipment,
    },
    updatedBy,
  };

  return Vue.axios.put('location/equipment', request).catch((error) => { throw error.response.data; });
}

export default {
  getLocationsWithEquipment,
  updateEquipment,
};

import Vue from 'vue';

function roundToNearestMinute(date) {
  const roundedDate = new Date(date);

  const seconds = date.getSeconds() + (date.getMilliseconds() / 1000);
  const minutes = date.getMinutes() + Math.round(seconds / 60);

  roundedDate.setMilliseconds(0);
  roundedDate.setSeconds(0);
  roundedDate.setMinutes(minutes);

  return roundedDate;
}

export default {
  getShortTimeString(date) {
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }).substr(0, 5);
  },
  convertToUTC(date) {
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  },
  getLocaleDateStringFromUTC(date) {
    return Vue.moment(date).utc().format('YYYY/MM/DD hh:mm A');
  },
  getISOStringNoTime(date) {
    return date.toISOString().split('T')[0];
  },
  getNearestTime(time, intervalInMinutes) {
    let timeToReturn = new Date(time);
    timeToReturn = roundToNearestMinute(timeToReturn);
    timeToReturn.setMinutes(Math.round(timeToReturn.getMinutes() / intervalInMinutes) * intervalInMinutes);
    return timeToReturn;
  },
  getAmountOfTimesBetweenDatesByInterval(startDate, endDate, timeIntervalInMinutes) {
    const currentDate = startDate;
    const dateArray = [];
    while (currentDate <= endDate) {
      dateArray.push(new Date(currentDate));
      currentDate.setMinutes(currentDate.getMinutes() + timeIntervalInMinutes);
    }
    return dateArray.length;
  },
};

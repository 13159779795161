import Vue from 'vue';
import _ from 'lodash';

const getDefaultState = () => ({
  roles: [],
  yards: [],
  maxAppointmentsPerTimeSlot: 0,
  appointmentFirstAvailableTime: {
    hour: 0,
    minute: 0,
  },
  appointmentLastAvailableTime: {
    hour: 23,
    minute: 0,
  },
  appointmentIntervalInMinutes: 30,
  appointmentIntervalCount: 0,
});

const state = getDefaultState();

const getters = {
  yards() {
    return state.yards;
  },
  roles() {
    return state.roles;
  },
  roleNames() {
    return _.map(state.roles, 'name');
  },
  appointmentFirstAvailableTime() {
    return state.appointmentFirstAvailableTime;
  },
  appointmentLastAvailableTime() {
    return state.appointmentLastAvailableTime;
  },
  appointmentIntervalInMinutes() {
    return state.appointmentIntervalInMinutes;
  },
  appointmentIntervalCount(state) {
    const totalHoursOfOperation = state.appointmentLastAvailableTime.hour - state.appointmentFirstAvailableTime.hour;
    const intervalMinutesToHour = state.appointmentIntervalInMinutes / 60;
    return totalHoursOfOperation / intervalMinutesToHour;
  },
  maxAppointmentsPerTimeSlot() {
    return state.maxAppointmentsPerTimeSlot;
  },
};

const actions = {
  getRoles({ commit }) {
    Vue.axios.get('/role').then((result) => {
      commit('addRoles', result.data);
    });
  },
  setAppointmentFirstAvailableTime({ commit }, value) {
    commit('setAppointmentFirstAvailableTime', value);
  },
  setAppointmentLastAvailableTime({ commit }, value) {
    commit('setAppointmentLastAvailableTime', value);
  },
  setAppointmentIntervalInMinutes({ commit }, value) {
    commit('setAppointmentIntervalInMinutes', value);
  },
  setMaxAppointmentsPerTimeSlot({ commit }, value) {
    commit('setMaxAppointmentsPerTimeSlot', value);
  },
};

const mutations = {
  addRoles(state, roles) {
    state.roles = roles;
  },
  setAppointmentFirstAvailableTime(state, value) {
    state.appointmentFirstAvailableTime = value;
  },
  setAppointmentLastAvailableTime(state, value) {
    state.appointmentLastAvailableTime = value;
  },
  setAppointmentIntervalInMinutes(state, value) {
    state.appointmentIntervalInMinutes = value;
  },
  setMaxAppointmentsPerTimeSlot(state, value) {
    state.maxAppointmentsPerTimeSlot = value;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

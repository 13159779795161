const BARE = 'Bare';
const EMPTY = 'Empty';
const LOADED = 'Loaded';
const PARTIAL = 'Partial';
const READY = 'Ready To Go';
const EQUIPMENT_ONLY = 'Equipment Only';

export default {
  BARE,
  EMPTY,
  LOADED,
  PARTIAL,
  READY,
  EQUIPMENT_ONLY,
};

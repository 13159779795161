<template>
      <v-app-bar class="secondary" app>
        <v-app-bar-nav-icon
          @click.stop="setIsSideNavVisible(true)"
          v-if="showMenuIcon && isLoggedIn"
        />
        <v-menu v-if="selectedYard && selectedYard.yardId && !isManageUsersRoute">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              outlined
              v-bind="attrs"
              v-on="on"
              large
              :class="{ 'disable-events': !hasMultipleYards }"
            >
              {{ selectedYard.name }}
              <v-icon v-if="hasMultipleYards">keyboard_arrow_down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in accessibleYards"
              :key="index"
              @click="yardSelected(item)"
            >
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-spacer></v-spacer>
        <v-toolbar-title>
          {{ user ? this.fullName  : "" }}&nbsp;&nbsp;&nbsp;
          <v-btn v-if="isLoggedIn" color="primary" @click="logoutClicked">Logout</v-btn>
        </v-toolbar-title>
      </v-app-bar>
</template>
<script>
// TODO Mason Move "app" components up a level
import { mapActions, mapGetters } from 'vuex';

export default {
  computed: {
    hasMultipleYards() {
      return this.accessibleYards.length > 1;
    },
    showMenuIcon() {
      return !this.isSideNavVisible;
    },
    isManageUsersRoute() {
      return this.$route.name === 'ManageUsersView';
    },
    ...mapGetters('account', ['fullName', 'user', 'isLoggedIn', 'selectedYard', 'accessibleYards']),
    ...mapGetters('sidenav', ['isSideNavVisible']),
  },
  methods: {
    ...mapActions('sidenav', ['setIsSideNavVisible']),
    ...mapActions('account', ['changeSelectedYard', 'logout']),
    ...mapActions({ resetStoreState: 'resetStoreState' }),
    ...mapActions('expectedGateActivity', ['getExpectedGateActivities']),
    ...mapActions('check', ['getLostEquipment']),
    yardSelected(event) {
      this.changeSelectedYard(event);
      if (this.isLoggedIn) {
        this.getExpectedGateActivities(this.selectedYard.yardId);
        this.getLostEquipment();
      }
      this.resetStoreState();
    },
    logoutClicked() {
      this.logout();
    },
  },
};
</script>
<style scoped>
.disable-events {
  pointer-events: none;
}
</style>

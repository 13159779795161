import Vue from 'vue';
import _ from 'lodash';
import moveService from '../services/moveService';
import locationMapper from '../mappers/locationMapper';
import router from '../router/index';
import equipmentMapper from '../mappers/equipmentMapper';

const moveFrom = _.cloneDeep(locationMapper.defaultLocation);
const moveTo = _.cloneDeep(locationMapper.defaultLocation);
const equip = _.cloneDeep(equipmentMapper.defaultEquipment);

const getDefaultMove = () => ({
  createdBy: '',
  createdDate: null,
  equipment: equip,
  fromLocation: moveFrom,
  toLocation: moveTo,
  assignedTo: '',
  startDate: null,
  completedDate: null,
});

const getDefaultState = () => ({
  move: getDefaultMove(),
  pendingMoves: [],
  completedMovesToday: [],
  isLoadingCompletedMoves: false,
  isLoadingPendingMoves: false,
  showScheduleMoveComponent: false,
  moveToLocation: {},
  filter: '',
});

const state = getDefaultState();

const getters = {
  filter() {
    return state.filter;
  },
  pendingMoves() {
    return state.pendingMoves;
  },
  completedMovesToday() {
    return state.completedMovesToday;
  },
  isLoadingPendingMoves() {
    return state.isLoadingPendingMoves;
  },
  isLoadingCompletedMoves() {
    return state.isLoadingCompletedMoves;
  },
  showScheduleMoveComponent() {
    return state.showScheduleMoveComponent;
  },
  moveToLocation() {
    return state.move.toLocation;
  },
  hasMoveToLocation() {
    return !_.isEmpty(state.moveToLocation.displayName);
  },
  filteredPendingMoves() {
    return state.pendingMoves.filter(
      (move) => move.equipment.number.toLowerCase().includes(state.filter.toLowerCase())
        || move.equipment.type.toLowerCase().indexOf(state.filter.toLowerCase()) >= 0
        || move.fromLocation.displayName.indexOf(state.filter.toLowerCase()) >= 0
        || move.toLocation.displayName.indexOf(state.filter.toLowerCase()) >= 0,
    );
  },
  move() {
    return state.move;
  },
  pendingMoveById: (state) => (moveId) => state.pendingMoves.find((move) => move.id === moveId),
  pendingMoveByLocationAndEquipment: (state) => (fromLocationId, equipmentId) => state.pendingMoves.find((move) => move.fromLocation.id === fromLocationId && move.equipment.id === equipmentId),
};

const actions = {
  async saveMove({ dispatch }, request) {
    const move = await moveService.saveMove(request);
    dispatch('addedPendingMove', move);
  },
  async updateMove({ dispatch }, request) {
    const move = await moveService.updateMove(request);
    dispatch('updatedMove', { updatedMove: move, existingMoveId: request.existingMoveId });
  },
  addedPendingMove({ dispatch, commit }, move) {
    const updateEquipmentStateRequest = {
      locationId: move.fromLocation.id,
      equipmentState: move.equipment.state,
    };
    if (updateEquipmentStateRequest.equipmentState != null) {
      dispatch('location/updateEquipmentStateForLocation', updateEquipmentStateRequest, { root: true });
    }
    dispatch('location/addMoveFrom', { equipment: move.equipment, location: move.fromLocation }, { root: true });
    dispatch('location/addMoveTo', move.toLocation, { root: true });
    commit('addPendingMove', move);
  },
  updatedMove({ dispatch, commit, getters }, request) {
    const existingMove = getters.pendingMoveById(request.existingMoveId);
    dispatch('location/removeMoveTo', { location: existingMove.toLocation }, { root: true });
    dispatch('location/addMoveTo', request.updatedMove.toLocation, { root: true });
    commit('updatePendingMove', request.updatedMove);
  },
  async removeMove({ dispatch, rootGetters }, move) {
    dispatch('removedPendingMove', move);
    move.deletedBy = rootGetters['account/fullName'];
    await moveService.removeMove(move);
  },
  removedPendingMove({ commit, dispatch }, move) {
    dispatch('location/removeMoveFrom', { equipment: move.equipment, location: move.fromLocation }, { root: true });
    dispatch('location/removeMoveTo', { equipment: move.equipment, location: move.toLocation }, { root: true });
    commit('removePendingMove', move);
  },
  async getPendingMoves({ commit }, shouldShowLoading) {
    if (shouldShowLoading) {
      commit('setIsLoadingPendingMoves', true);
    }

    const result = await moveService.getPendingMoves();
    commit('setPendingMoves', result);
    commit('setIsLoadingPendingMoves', false);
  },
  async refreshPendingMoves({ commit }) {
    const result = await moveService.getPendingMoves();
    commit('updatePendingMoves', result);
  },
  async getCompletedMovesToday({ commit }, shouldShowLoading) {
    const today = new Date().toISOString();

    if (shouldShowLoading) {
      commit('setIsLoadingCompletedMoves', true);
    }

    const result = await moveService.getCompletedMoves(today);
    commit('setCompletedMovesToday', result);
    commit('setIsLoadingCompletedMoves', false);
  },
  async startMove({ dispatch, rootGetters }, move) {
    move.assignedTo = rootGetters['account/fullName'];
    dispatch('setAssignedTo', rootGetters['account/fullName']);

    const startDate = new Date().toISOString();
    move.startDate = startDate;
    dispatch('setStartDate', startDate);

    await moveService.start(move);
  },
  stopMove({ dispatch, rootGetters }, move) {
    move.completedDate = new Date().toISOString();
    move.completedBy = rootGetters['account/fullName'];
    moveService.stop(move);
    dispatch('stoppedPendingMove', move);
  },
  stoppedPendingMove({ dispatch, commit }, move) {
    dispatch('location/removeMoveFrom', { equipment: move.equipment, location: move.fromLocation }, { root: true });
    dispatch('location/removeMoveTo', { equipment: move.equipment, location: move.toLocation }, { root: true });
    dispatch('location/removeEquipmentFromLocation', { locationID: move.fromLocation.id, equipment: move.equipment }, { root: true });
    dispatch('location/addEquipmentToLocation', { locationID: move.toLocation.id, equipment: move.equipment }, { root: true });
    commit('removePendingMove', move);
    commit('addCompletedMove', move);
  },
  updatedPendingMove({ commit }, move) {
    commit('updatePendingMove', move);
  },
  resetState({ commit, dispatch }) {
    commit('resetState');
    if (router.currentRoute.name === 'JockeyView') {
      dispatch('getPendingMoves');
      dispatch('getCompletedMovesToday');
    }
  },
  resetMove({ commit }) {
    commit('resetMove');
  },
  setShowScheduleMoveComponent({ commit }, value) {
    commit('setShowScheduleMoveComponent', value);
  },
  setMoveFromLocation({ commit }, value) {
    commit('setMoveFromLocation', value);
  },
  setMoveToLocation({ commit }, value) {
    commit('setMoveToLocation', value);
  },
  setAssignedTo({ commit }, value) {
    commit('setAssignedTo', value);
  },
  setStartDate({ commit }, value) {
    commit('setStartDate', value);
  },
  setMoveEquipment({ commit }, value) {
    commit('setMoveEquipment', value);
  },
  setFilter({ commit }, value) {
    commit('setFilter', value);
  },
  setPendingMoves({ commit }, value) {
    commit('setPendingMoves', value);
  },
  setMoveCreatedBy({ commit }, value) {
    commit('setMoveCreatedBy', value);
  },
};

const mutations = {
  addPendingMove(state, move) {
    const existingPendingMove = state.pendingMoves.find((pendingMove) => pendingMove.id === move.id);
    if (existingPendingMove === undefined) {
      state.pendingMoves.push(move);
    }
  },
  updatePendingMove(state, move) {
    const pendingMoveIndex = state.pendingMoves.findIndex((pendingMove) => pendingMove.id === move.id);

    if (pendingMoveIndex >= 0) {
      Vue.set(state.pendingMoves, pendingMoveIndex, move);
    }
  },
  setPendingMoves(state, pendingMoves) {
    state.pendingMoves = pendingMoves;
  },
  updatePendingMoves(state, pendingMoves) {
    for (let i = 0; i < pendingMoves.length; i += 1) {
      const existingPendingMoveIndex = state.pendingMoves.findIndex((move) => move.id === pendingMoves[i].id);
      if (existingPendingMoveIndex < 0) {
        this.commit('move/addPendingMove', pendingMoves[i]);
      } else if (existingPendingMoveIndex >= 0) {
        this.commit('move/updatePendingMove', pendingMoves[i]);
      }
    }
    for (let i = 0; i < state.pendingMoves.length; i += 1) {
      const deletedPendingMove = pendingMoves.find((move) => move.id === state.pendingMoves[i].id);
      if (!deletedPendingMove) {
        this.commit('move/removePendingMove', state.pendingMoves[i]);
      }
    }
  },
  setCompletedMovesToday(state, completedMovesToday) {
    state.completedMovesToday = completedMovesToday;
  },
  setIsLoadingPendingMoves(state, value) {
    state.isLoadingPendingMoves = value;
  },
  setIsLoadingCompletedMoves(state, value) {
    state.isLoadingCompletedMoves = value;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  resetMove() {
    Object.assign(state.move, getDefaultMove());
  },
  removePendingMove(state, pendingMove) {
    const pendingMoveIndex = state.pendingMoves.findIndex((move) => move.id === pendingMove.id);

    if (pendingMoveIndex >= 0) {
      Vue.delete(state.pendingMoves, pendingMoveIndex);
    }
  },
  addCompletedMove(state, completedMove) {
    const existingCompletedMove = state.completedMovesToday.find((move) => move.id === completedMove.id);
    if (!existingCompletedMove) {
      state.completedMovesToday.push(completedMove);
    }
  },
  setShowScheduleMoveComponent(state, value) {
    state.showScheduleMoveComponent = value;
  },
  setMoveFromLocation(state, location) {
    state.move.fromLocation = _.cloneDeep(location);
  },
  setMoveToLocation(state, location) {
    state.move.toLocation = _.cloneDeep(location);
    state.moveToLocation = _.cloneDeep(location);
  },
  setAssignedTo(state, fullName) {
    state.move.assignedTo = fullName;
  },
  setStartDate(state, startDate) {
    state.move.startDate = startDate;
  },
  setMoveEquipment(state, equipment) {
    state.move.equipment = _.cloneDeep(equipment);
  },
  setFilter(state, value) {
    state.filter = value;
  },
  setMoveCreatedBy(state, value) {
    state.move.createdBy = value;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

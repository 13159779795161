import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueMaterial from 'vue-material';
import moment from 'vue-moment';
import JsonExcel from 'vue-json-excel';
import configureAxios from '@/axios/axiosConfig';
import userStatuses from '@/constants/userStatuses';
import App from './App.vue';
import router from './router';
import store from './store';
import 'vue-material/dist/vue-material.min.css';
import 'vue-material/dist/theme/default.css';
import vuetify from './plugins/vuetify';
import TruckIcon from './assets/truck.png';

Vue.component('downloadExcel', JsonExcel);
Vue.component('TruckIcon', TruckIcon);

Vue.config.productionTip = false;
Vue.use(VueMaterial);
Vue.use(moment);
Vue.use(VueAxios, axios);

configureAxios();

async function initialize() {
  const isLoggedIn = store.getters['account/isLoggedIn'];
  if (isLoggedIn) {
    const localStorageUser = JSON.parse(localStorage.getItem('user'));
    const userId = localStorageUser.id;

    await store.dispatch('yard/getYards');
    await store.dispatch('location/getLocations');
    await store.dispatch('admin/getUsers');

    const user = store.getters['admin/userById'](userId);
    store.dispatch('account/setUser', user);
    if (user.status !== userStatuses.ACTIVE) {
      store.dispatch('account/logout');
    }
  }

  new Vue({
    store,
    router,
    vuetify,
    render: (h) => h(App),
  }).$mount('#app');
}

initialize();

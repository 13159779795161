import Vue from 'vue';
import _ from 'lodash';
import router from '../router/index';
import checkService from '../services/checkService';
import checkStatuses from '../constants/checkStatuses';
import equipmentService from '../services/equipmentService';
import locationMapper from '../mappers/locationMapper';

const getDefaultState = () => ({
  checks: [],
  activeChecks: [],
  selectedCheck: {},
  isLoading: false,
  lostEquipment: [],
  isAddingEquipmentToLocation: false,
  showDeleteLostEquipmentDialog: false,
});

const state = getDefaultState();

const getters = {
  isAddingEquipmentToLocation() {
    return state.isAddingEquipmentToLocation;
  },
  checks() {
    return state.checks;
  },
  activeChecks() {
    return state.activeChecks;
  },
  selectedCheck() {
    return state.selectedCheck;
  },
  isLoading(state) {
    return state.isLoading;
  },
  canCheck() {
    return _.isEmpty(state.selectedCheck);
  },
  hasOngoingChecks(state) {
    return state.activeChecks.length > 0;
  },
  lostEquipment(state) {
    return state.lostEquipment;
  },
  lostEquipmentByType(state, getters, rootState, rootGetters) {
    const chosenType = rootGetters['gate/gateEntry'].equipment.type;
    const lostEquipmentByType = state.lostEquipment.filter((value) => value.equipment.type === chosenType);
    return lostEquipmentByType;
  },
  filteredLostEquipment: (state) => (searchTerm) => state.lostEquipment.filter((lostEquip) => lostEquip.equipment.number.toLowerCase().includes(searchTerm.toLowerCase())),
  lostEquipmentByEquipmentNumber: (state) => (equipmentNumber) => {
    const lostEquipment = state.lostEquipment.find((lostEquipment) => lostEquipment.equipment.number === equipmentNumber);
    return lostEquipment;
  },
  confirmedEquipment(state, getters, rootState, rootGetters) {
    const checkEntries = rootGetters['checkView/checkEntries'];
    return checkEntries.filter((w) => w.status === checkStatuses.CONFIRMED && w.location.equipment.length === 1);
  },
  confirmedLocations(state, getters, rootState, rootGetters) {
    const checkEntries = rootGetters['checkView/checkEntries'];
    return checkEntries.filter((w) => w.status === checkStatuses.CONFIRMED && w.location.equipment.length !== 1);
  },
  activeCheckById: (state) => (checkId) => state.activeChecks.find((check) => check.id === checkId),
  showDeleteLostEquipmentDialog(state) {
    return state.showDeleteLostEquipmentDialog;
  },
};

const actions = {
  async getActiveChecks({ commit }, showLoading) {
    if (showLoading) {
      commit('setIsLoading', showLoading);
    }

    const checks = await checkService.getActiveChecks();

    commit('saveActiveChecks', checks);
    commit('setIsLoading', false);
  },
  async getChecks({ commit }) {
    commit('setIsLoading', true);

    const checks = await checkService.getChecks();
    commit('saveChecks', checks);
    commit('setIsLoading', false);
  },
  async getLostEquipment({ commit }) {
    const lostEquipmentList = await equipmentService.getLostEquipment();
    commit('setLostEquipment', lostEquipmentList);
  },
  async refreshCheckEntries({ dispatch }) {
    await dispatch('getActiveChecks', false);
    if (!_.isEmpty(state.selectedCheck)) {
      const updatedCheck = _.find(state.activeChecks, (check) => check.id === state.selectedCheck.id);
      if (updatedCheck) {
        dispatch('setSelectedCheck', updatedCheck);
      }
    }
  },
  async startCheck({ commit, dispatch, rootGetters }) {
    const date = new Date().toISOString();
    const startedBy = rootGetters['account/fullName'];
    await checkService.startCheck(date, startedBy).then((result) => {
      dispatch('addedActiveCheck', result);
      commit('setSelectedCheck', result);
      dispatch('location/clearStatuses', null, { root: true });
    });
  },
  addedActiveCheck({ commit }, check) {
    commit('addActiveCheck', check);
  },
  async stopCheck({ commit, dispatch }, check) {
    dispatch('stoppedActiveCheck', check);
    commit('setSelectedCheck', {});
    await checkService.stopCheck(check);
  },
  stoppedActiveCheck({ commit }, check) {
    commit('removeActiveCheck', check);
  },
  setSelectedCheck: ({ commit }, check) => {
    commit('setSelectedCheck', check);
    const checkData = {
      confirmedEquipment: check.confirmedEquipment,
      confirmedLocations: check.confirmedLocations,
    };
    commit('location/setStatuses', checkData, { root: true });
  },
  resetState({ commit, dispatch }) {
    commit('resetState');
    if (router.currentRoute.name === 'YardCheckView') {
      dispatch('getActiveChecks');
    }
    if (router.currentRoute.name === 'YardCheckHistoryView') {
      dispatch('getChecks');
    }
  },
  async addConfirmedLocation({ dispatch, rootGetters }, request) {
    dispatch('addedConfirmedLocation', { checkID: request.checkID, location: request.location });
    const updatedBy = rootGetters['account/fullName'];
    await checkService.addConfirmedLocation(request.checkID, request.location, updatedBy);
  },
  addedConfirmedLocation({ commit }, request) {
    if (state.selectedCheck.id === request.checkID) {
      const updateStatusRequest = { locationID: request.location.id, status: checkStatuses.CONFIRMED };
      commit('location/updateLocationStatus', updateStatusRequest, { root: true });
    }

    commit('addConfirmedLocation', request);
  },
  addedConfirmedLocations({ dispatch }, request) {
    for (let i = 0; i < request.length; i += 1) {
      dispatch('addedConfirmedLocation', request[i]);
    }
  },
  async removeConfirmedLocation({ dispatch }, request) {
    dispatch('removedConfirmedLocation', { checkID: request.checkID, location: request.location });
    await checkService.removeConfirmedLocation(request.checkID, request.location);
  },
  removedConfirmedLocation({ commit }, request) {
    if (state.selectedCheck.id === request.checkID) {
      const updateStatusRequest = { locationID: request.location.id, status: '' };
      commit('location/updateLocationStatus', updateStatusRequest, { root: true });
    }

    commit('removeConfirmedLocation', request);
  },
  removedConfirmedLocations({ dispatch }, request) {
    for (let i = 0; i < request.length; i += 1) {
      dispatch('removedConfirmedLocation', request[i]);
    }
  },
  async addConfirmedEquipment({ dispatch, state, rootGetters }, request) {
    if (request.equipment.id) {
      dispatch('addedConfirmedEquipment', { checkID: request.checkID, locationEquipmentRequest: locationMapper.mapToLocationEquipmentRequest(request.location.id, request.equipment) });
      dispatch('location/setShowEditEquipmentDialog', false, { root: true });
    } else {
      state.isAddingEquipmentToLocation = true;
    }

    try {
      const updatedBy = rootGetters['account/fullName'];
      const addedEquipment = await checkService.addConfirmedEquipment(request.checkID, request.location.id, request.equipment, updatedBy);
      request.equipment = addedEquipment;
      dispatch('addedConfirmedEquipment', { checkID: request.checkID, locationEquipmentRequest: locationMapper.mapToLocationEquipmentRequest(request.location.id, request.equipment) });
    } catch (error) {
      dispatch('alert/raiseError', error.message, { root: true });
    }

    dispatch('location/setShowEditEquipmentDialog', false, { root: true });
    state.isAddingEquipmentToLocation = false;
  },
  addedConfirmedEquipment({ commit, state, dispatch }, request) {
    if (state.selectedCheck.id === request.checkID) {
      const updateStatusRequest = { equipmentID: request.locationEquipmentRequest.equipment.id, status: checkStatuses.CONFIRMED };
      commit('location/updateEquipmentStatus', updateStatusRequest, { root: true });
    }

    commit('addConfirmedEquipment', { checkID: request.checkID, equipment: request.locationEquipmentRequest.equipment });

    if (request.locationEquipmentRequest.locationID !== '') {
      dispatch('location/addEquipmentToLocation', request.locationEquipmentRequest, { root: true });
    }

    commit('removeLostEquipment', request.locationEquipmentRequest.equipment);
  },
  addedConfirmedEquipments({ dispatch }, request) {
    for (let i = 0; i < request.length; i += 1) {
      const addedConfirmedEquipmentRequest = {
        checkID: request[i].checkID,
        locationEquipmentRequest: locationMapper.mapToLocationEquipmentRequest(request[i].location.id, request[i].equipment),
      };
      dispatch('addedConfirmedEquipment', addedConfirmedEquipmentRequest);
    }
  },
  async removeConfirmedEquipment({ dispatch, rootGetters }, request) {
    dispatch('removedConfirmedEquipment', request);
    const updatedBy = rootGetters['account/fullName'];
    await checkService.removeConfirmedEquipment(request.checkID, request.location.id, request.equipment, updatedBy);
  },
  removedConfirmedEquipment({ commit }, request) {
    if (state.selectedCheck.id === request.checkID) {
      const updateStatusRequest = { equipmentID: request.equipment.id, status: '' };
      commit('location/updateEquipmentStatus', updateStatusRequest, { root: true });
    }

    const updateStatusRequest = { locationID: request.location.id, status: '' };
    commit('location/updateLocationStatus', updateStatusRequest, { root: true });

    commit('removeConfirmedEquipment', { equipmentID: request.equipment.id, checkID: request.checkID });
  },
  removedConfirmedEquipments({ dispatch }, request) {
    for (let i = 0; i < request.length; i += 1) {
      dispatch('removedConfirmedEquipment', request[i]);
    }
  },
  addLostEquipment({ dispatch, rootGetters }, request) {
    dispatch('addedLostEquipment', request.equipment);
    dispatch('location/removeEquipmentFromLocation', { locationID: request.location.id, equipment: request.equipment }, { root: true });
    const updatedBy = rootGetters['account/fullName'];
    checkService.addLostEquipment(request.location.id, request.equipment, updatedBy);
  },
  addedLostEquipment({ commit }, equipment) {
    commit('addLostEquipment', equipment);
  },
  removeLostEquipment({ dispatch, rootGetters }, equipment) {
    dispatch('removedLostEquipment', equipment);
    const updatedBy = rootGetters['account/fullName'];
    checkService.removeLostEquipment(equipment, updatedBy);
  },
  removedLostEquipment({ commit }, equipment) {
    commit('removeLostEquipment', equipment);
  },
  setLostEquipment({ commit }, lostEquipment) {
    commit('setLostEquipment', lostEquipment);
  },
  setIsLoading({ commit }, value) {
    commit('setIsLoading', value);
  },
  setLostEquipmentByEquipmentNumber({ commit }, equipmentNumber) {
    commit('setLostEquipmentByEquipmentNumber', equipmentNumber);
  },
  setShowDeleteLostEquipmentDialog({ commit }, value) {
    commit('setShowDeleteLostEquipmentDialog', value);
  },
};

const mutations = {
  saveActiveChecks(state, checks) {
    state.activeChecks = checks;
  },
  saveChecks(state, checks) {
    state.checks = checks;
  },
  addActiveCheck(state, check) {
    const existingActiveCheck = state.activeChecks.find((activeCheck) => activeCheck.id === check.id);

    if (existingActiveCheck === undefined) {
      state.activeChecks.push(check);
    }
  },
  addConfirmedEquipment(state, request) {
    const existingActiveCheck = state.activeChecks.find((activeCheck) => activeCheck.id === request.checkID);

    if (existingActiveCheck && existingActiveCheck.confirmedEquipment) {
      const existingConfirmedEquipment = existingActiveCheck.confirmedEquipment.find((equipment) => equipment.id === request.equipment.id);

      if (existingConfirmedEquipment === undefined) {
        existingActiveCheck.confirmedEquipment.push(request.equipment);
      }
    }
  },
  removeConfirmedEquipment(state, request) {
    const existingActiveCheck = state.activeChecks.find((activeCheck) => activeCheck.id === request.checkID);

    if (existingActiveCheck) {
      const existingConfirmedEquipmentIndex = existingActiveCheck.confirmedEquipment.findIndex((equipment) => equipment.id === request.equipmentID);

      if (existingConfirmedEquipmentIndex >= 0) {
        Vue.delete(existingActiveCheck.confirmedEquipment, existingConfirmedEquipmentIndex);
      }
    }
  },
  addConfirmedLocation(state, request) {
    const existingActiveCheck = state.activeChecks.find((activeCheck) => activeCheck.id === request.checkID);

    if (existingActiveCheck) {
      if (existingActiveCheck.confirmedLocations) {
        const existingConfirmedLocation = existingActiveCheck.confirmedLocations.find((location) => location.id === request.location.id);
        if (existingConfirmedLocation === undefined) {
          existingActiveCheck.confirmedLocations.push(request.location);
        }
      } else {
        existingActiveCheck.confirmedLocations = [request.location];
      }
    }
  },
  removeConfirmedLocation(state, request) {
    const existingActiveCheck = state.activeChecks.find((activeCheck) => activeCheck.id === request.checkID);

    if (existingActiveCheck) {
      const existingConfirmedLocationIndex = existingActiveCheck.confirmedLocations.findIndex((location) => location.id === request.locationID);

      if (existingConfirmedLocationIndex >= 0) {
        Vue.delete(existingActiveCheck.confirmedLocations, existingConfirmedLocationIndex);
      }
    }
  },
  removeActiveCheck(state, check) {
    const activeCheckIndex = state.activeChecks.findIndex((activeCheck) => activeCheck.id === check.id);

    if (activeCheckIndex >= 0) {
      Vue.delete(state.activeChecks, activeCheckIndex);
    }
  },
  setSelectedCheck(state, check) {
    state.selectedCheck = check;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setIsLoading(state, value) {
    state.isLoading = value;
  },
  setLostEquipment(state, lostEquipmentList) {
    for (let i = 0; i < lostEquipmentList.length; i += 1) {
      const existingLostEquipment = state.lostEquipment.find((lostEquipment) => lostEquipment.equipment.id === lostEquipmentList[i].equipment.id);
      if (!existingLostEquipment) {
        state.lostEquipment.push(lostEquipmentList[i]);
      }
    }
  },
  setLostEquipmentByEquipmentNumber(state, equipmentNumber) {
    const lostEquipment = state.lostEquipment.find((lostEquipment) => lostEquipment.equipment.number === equipmentNumber);
    state.lostEquipmentByEquipmentNumber = lostEquipment;
  },
  addLostEquipment(state, equipment) {
    const existingLostEquipment = state.lostEquipment.find((lostEquipment) => lostEquipment.equipment.id === equipment.id);

    if (existingLostEquipment === undefined) {
      const d = new Date().toLocaleString('en-US', { timeZone: 'America/New_York' });
      const thisLostEquipment = {
        insertedDate: d,
        equipment,
      };
      state.lostEquipment.push(thisLostEquipment);
    }
  },
  removeLostEquipment(state, equipment) {
    const lostEquipmentIndex = state.lostEquipment.findIndex((w) => w.equipment.id === equipment.id);

    if (lostEquipmentIndex >= 0) {
      Vue.delete(state.lostEquipment, lostEquipmentIndex);
    }
  },
  setShowDeleteLostEquipmentDialog(state, value) {
    state.showDeleteLostEquipmentDialog = value;
  },
};

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations,
};

function mapFromApiCarrier(apiCarrier) {
  const carrier = {
    id: apiCarrier.id,
    scac: apiCarrier.scac,
    name: apiCarrier.name,
  };

  return carrier;
}

function mapToApiCarrier(carrier) {
  const apiCarrier = {
    scac: carrier.scac,
    name: carrier.name,
  };

  if (carrier.id) {
    apiCarrier.id = carrier.id;
  }

  return apiCarrier;
}

export default {
  mapFromApiCarrier,
  mapToApiCarrier,
};

import eventColorLogic from '../logic/eventColorLogic';

function mapToCalendarEvent(appointment) {
  let startDate = new Date(appointment.startDate);
  startDate = new Date(startDate.setHours(appointment.startTime.hour));
  startDate = new Date(startDate.setMinutes(appointment.startTime.minute));

  let endDate = new Date(appointment.startDate);
  if (appointment.endDate) {
    endDate = new Date(appointment.endDate);
    if (appointment.endTime && appointment.endTime.hour != null) {
      endDate = new Date(endDate.setHours(appointment.endTime.hour));
      endDate = new Date(endDate.setMinutes(appointment.endTime.minute));
    }
  }

  const color = eventColorLogic.getEventColor(appointment.direction);

  const event = {
    appointment,
    name: appointment.number,
    start: startDate,
    end: endDate,
    color,
    timed: (appointment.startTime.hour != null),
  };

  return event;
}

export default {
  mapToCalendarEvent,
};

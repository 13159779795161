import yard from '../store/yardModule';

const defaultUser = {
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  defaultYard: {},
  accessibleYards: [],
  roles: [],
  status: '',
};

/* TODO Mason - We need to clean this up. These aren't mapping methods and we shouldn't be
  bringing in a store module and accessing its state. */
function mapYardFromId(defaultYardId) {
  return yard.state.yards.find((yard) => defaultYardId === yard.yardId);
}

function mapYardsFromIds(accessibleYardIds) {
  return yard.state.yards.filter((yards) => accessibleYardIds.includes(yards.yardId));
}

function mapYardsToIds(yards) {
  const yardIds = [];
  for (let i = 0; i < yards.length; i += 1) {
    const { yardId } = yards[i];
    yardIds.push(yardId);
  }

  return yardIds;
}

function formatName(firstName, lastName) {
  return `${firstName} ${lastName}`;
}

function mapFromApiUser(apiUser) {
  const user = {
    id: apiUser.id,
    firstName: apiUser.firstName,
    lastName: apiUser.lastName,
    fullName: formatName(apiUser.firstName, apiUser.lastName),
    email: apiUser.email,
    defaultYard: mapYardFromId(apiUser.defaultYardId),
    accessibleYards: [],
    roles: apiUser.roles,
    status: apiUser.status,
  };

  if (apiUser.accessibleYardIds) {
    user.accessibleYards = mapYardsFromIds(apiUser.accessibleYardIds);
  }

  return user;
}

function mapFromApiUsers(apiUsers) {
  const userList = [];

  if (apiUsers) {
    apiUsers.forEach((apiUser) => {
      const user = mapFromApiUser(apiUser);
      userList.push(user);
    });
  }

  return userList;
}

function mapToApiUser(user) {
  const apiUser = {
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    defaultYardId: user.defaultYard.yardId,
    accessibleYardIds: [],
    roles: user.roles,
    status: user.status,
  };

  if (user.accessibleYards) {
    apiUser.accessibleYardIds = mapYardsToIds(user.accessibleYards);
  }

  return apiUser;
}

export default {
  defaultUser,
  mapYardFromId,
  mapYardsFromIds,
  mapFromApiUser,
  mapFromApiUsers,
  mapToApiUser,
};

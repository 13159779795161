const CONTAINER = 'Container';
const CHASSIS = 'Chassis';
const VAN = 'Van';
const REEFER_VAN = 'Reefer Van';
const REEFER_CONTAINER = 'Reefer Container';
const FLATBED = 'Flatbed';
const TANKER = 'Tanker';

const ALL_TYPES = [
  CONTAINER,
  CHASSIS,
  VAN,
  REEFER_VAN,
  REEFER_CONTAINER,
  FLATBED,
  TANKER,
];

export default {
  CONTAINER,
  CHASSIS,
  VAN,
  REEFER_VAN,
  REEFER_CONTAINER,
  FLATBED,
  ALL_TYPES,
  TANKER,
};

import Vue from 'vue';
import _ from 'lodash';
import yardService from '../services/yardService';
import checkStatuses from '../constants/checkStatuses';
import locationService from '../services/locationService';

const getDefaultState = () => ({
  locations: [],
  groups: [],
  isLoading: false,
  showSpotSelectionDialog: false,
  selectedGroup: '',
  filter: '',
  showEditEquipmentDialog: false,
});

const state = getDefaultState();

function setLocationEquipmentHasPendingMove(locations, scheduledMoves) {
  const moveFromLocationIds = _.map(scheduledMoves, 'fromLocation.id');
  const moveToLocationIds = _.map(scheduledMoves, 'toLocation.id');
  for (let i = 0; i < locations.length; i += 1) {
    if (moveFromLocationIds.indexOf(locations[i].id) >= 0) {
      locations[i].hasMoveFrom = true;
    }
    if (moveToLocationIds.indexOf(locations[i].id) >= 0) {
      locations[i].hasMoveTo = true;
    }
    for (let j = 0; j < locations[i].equipment.length; j += 1) {
      const hasPendingMove = scheduledMoves.find((scheduledMove) => scheduledMove.equipment.id === locations[i].equipment[j].id);
      if (hasPendingMove) {
        locations[i].equipment[j].hasPendingMove = true;
      }
    }
  }

  return locations;
}

const getters = {
  filteredLocationsWithEquipment: (state, getters) => (searchTerm) => {
    const locationsToFilter = getters.locationsWithEquipment;
    return locationsToFilter.filter((location) => (location.equipment && location.equipment.find((equipment) => equipment.number.toLowerCase().includes(searchTerm.toLowerCase())))
    || location.displayName.indexOf(searchTerm.toLowerCase()) >= 0
    || location.groups.some((w) => w.toLowerCase().includes(searchTerm.toLowerCase()))
    || (location.equipment && location.equipment.find((equipment) => equipment.carrier.scac.toLowerCase().includes(searchTerm.toLowerCase())))
    || (location.equipment && location.equipment.find((equipment) => equipment.customer.name.toLowerCase().includes(searchTerm.toLowerCase())))
    || (location.equipment && location.equipment.find((equipment) => equipment.bookingNumber.toLowerCase().includes(searchTerm.toLowerCase())))
    || (location.equipment && location.equipment.find((equipment) => equipment.carrier.name.toLowerCase().includes(searchTerm.toLowerCase()))));
  },
  availableCapacity: (state, getters, rootState, rootGetters) => (location) => {
    const pendingMoves = rootGetters['move/pendingMoves'];
    const updatedLocation = getters.locationById(location.id);
    let locationEquipmentCapacity;
    let equipmentOnLocationCount;
    if (updatedLocation !== undefined) {
      locationEquipmentCapacity = updatedLocation.equipmentCapacity;
      equipmentOnLocationCount = updatedLocation.equipmentIDs.length;
    } else {
      locationEquipmentCapacity = location.equipmentCapacity;
      equipmentOnLocationCount = location.equipmentIDs.length;
    }

    const moveStartedFromLocation = pendingMoves.filter((pendingMove) => pendingMove.fromLocation.id === location.id && pendingMove.startDate !== null).length;
    const moveStartedToLocation = pendingMoves.filter((pendingMove) => pendingMove.toLocation.id === location.id && pendingMove.startDate !== null).length;

    return locationEquipmentCapacity - equipmentOnLocationCount - moveStartedToLocation + moveStartedFromLocation;
  },
  availableCapacityForGate: (state, getters, rootState, rootGetters) => (location) => {
    const pendingMoves = rootGetters['move/pendingMoves'];
    const updatedLocation = getters.locationById(location.id);
    let locationEquipmentCapacity;
    let equipmentOnLocationCount;
    if (updatedLocation !== undefined) {
      locationEquipmentCapacity = updatedLocation.equipmentCapacity;
      equipmentOnLocationCount = updatedLocation.equipmentIDs.length;
    } else {
      locationEquipmentCapacity = location.equipmentCapacity;
      equipmentOnLocationCount = location.equipmentIDs.length;
    }

    const moveStartedFromLocation = pendingMoves.filter((pendingMove) => pendingMove.fromLocation.id === location.id && pendingMove.startDate !== null).length;
    const movePendingToLocation = pendingMoves.filter((pendingMove) => pendingMove.toLocation.id === location.id).length;

    return locationEquipmentCapacity - equipmentOnLocationCount - movePendingToLocation + moveStartedFromLocation;
  },
  isLocationAtCapacity: (state, getters) => (location) => getters.availableCapacity(location) <= 0,
  equipmentLocation: (state) => (equipmentID) => state.locations.find((location) => location.equipment.find((equipment) => equipment.id === equipmentID)),
  equipmentById: (state) => (equipmentID) => {
    const stateLocation = state.locations.find((location) => location.equipment.find((equipment) => equipment.id === equipmentID));
    if (stateLocation) {
      const equipmentIndex = stateLocation.equipment.findIndex((equipment) => equipment.id === equipmentID);
      if (equipmentIndex >= 0) {
        return stateLocation.equipment[equipmentIndex];
      }
    }

    return undefined;
  },
  equipmentByNumber: (state) => (equipmentNumber) => {
    const stateLocation = state.locations.find((location) => location.equipment.find((equipment) => equipment.number.toLowerCase() === equipmentNumber.toLowerCase()));
    if (stateLocation) {
      const equipmentIndex = stateLocation.equipment.findIndex((equipment) => equipment.number.toLowerCase() === equipmentNumber.toLowerCase());
      if (equipmentIndex >= 0) {
        return stateLocation.equipment[equipmentIndex];
      }
    }

    return undefined;
  },
  filter() {
    return state.filter;
  },
  groupNames() {
    return _.map(state.groups, 'name');
  },
  locations() {
    return state.locations;
  },
  locationsWithoutStatuses(state) {
    return state.locations.filter((location) => location.status === '');
  },
  locationsBelowMaxCapacityByGroup: (state, getters) => (groupName) => {
    const locations = _.filter(state.locations, (location) => getters.availableCapacityForGate(location) > 0 && location.groups.indexOf(groupName) >= 0);
    return locations;
  },
  allUnstructuredOrAvailableLocationsByGroup: (state, getters) => (groupName) => {
    const locations = _.filter(state.locations, (location) => ((getters.availableCapacity(location) > 0 && location.type !== 'Unstructured') || (location.type === 'Unstructured'))
    && location.groups.indexOf(groupName) >= 0);
    return locations;
  },
  isLoading() {
    return state.isLoading;
  },
  showSpotSelectionDialog() {
    return state.showSpotSelectionDialog;
  },
  locationsByGroup: (state) => (groupName) => {
    const locations = state.locations.filter((w) => w.groups.indexOf(groupName) >= 0);
    return locations;
  },
  locationsWithEquipment(state) {
    const locations = state.locations.filter((location) => location.equipment.length > 0);
    return locations;
  },
  selectedGroup(state) {
    return state.selectedGroup;
  },
  locationByEquipmentNumberAndType: (state) => (equipmentNumber, equipmentType) => {
    const location = state.locations.find((location) => location.equipment.find((equipment) => equipment.number.toLowerCase() === equipmentNumber.toLowerCase()
    && equipment.type.toLowerCase() === equipmentType.toLowerCase()));
    return location;
  },
  locationByEquipmentNumber: (state) => (equipmentNumber) => (
    state.locations.find((location) => location.equipment.find((equipment) => equipment.number.toLowerCase() === equipmentNumber.toLowerCase()))
  ),
  locationById: (state) => (locationID) => {
    const stateLocation = state.locations.find((location) => location.id === locationID);
    if (stateLocation) {
      return stateLocation;
    }
    return undefined;
  },
  equipmentTypeCount: (state, getters) => (equipmentType) => {
    const equipmentFromLocations = _.flatten(_.map(getters.locationsWithEquipment, 'equipment'));
    return equipmentFromLocations.filter((equipment) => equipment.type === equipmentType).length;
  },
  showEditEquipmentDialog(state) {
    return state.showEditEquipmentDialog;
  },
};

const actions = {
  async getLocations({ commit, dispatch }) {
    commit('setIsLoading', true);
    const result = await yardService.getYard();
    commit('setGroups', result.groups);
    dispatch('config/setAppointmentFirstAvailableTime', result.appointmentStartTime, { root: true });
    dispatch('config/setAppointmentLastAvailableTime', result.appointmentEndTime, { root: true });
    dispatch('config/setAppointmentIntervalInMinutes', result.appointmentTimeIntervalInMinutes, { root: true });
    dispatch('config/setMaxAppointmentsPerTimeSlot', result.maxAppointmentOverlap, { root: true });

    const locations = setLocationEquipmentHasPendingMove(result.locations, result.scheduledMoves);
    commit('setLocations', locations);

    commit('setIsLoading', false);
  },
  resetState({ commit }) {
    commit('resetState');
  },
  setLocations({ commit }, locations) {
    commit('setLocations', locations);
  },
  setShowSpotSelectionDialog({ commit }, value) {
    commit('setShowSpotSelectionDialog', value);
  },
  addEquipmentToLocation({ commit }, data) {
    commit('addEquipmentToLocation', data);
  },
  updateEquipmentStateForLocation({ commit }, request) {
    commit('updateEquipmentStateForLocation', request);
  },
  removeEquipmentFromLocation({ commit }, request) {
    commit('removeEquipmentFromLocation', request);
  },
  addMoveFrom({ commit }, equipmentWithLocation) {
    commit('addMoveFrom', equipmentWithLocation);
  },
  removeMoveFrom({ commit }, request) {
    commit('removeMoveFrom', request);
  },
  addMoveTo({ commit }, location) {
    commit('addMoveTo', location);
  },
  removeMoveTo({ commit }, location) {
    commit('removeMoveTo', location);
  },
  setFilter({ commit }, filter) {
    commit('setFilter', filter);
  },
  setSelectedGroup({ commit }, selectedGroup) {
    commit('setSelectedGroup', selectedGroup);
  },
  updateEquipment({
    dispatch, commit, getters, rootGetters,
  }, request) {
    try {
      dispatch('updatedEquipment', request.equipment);
      const currentEquipmentLocation = getters.equipmentLocation(request.equipment.id);
      if (!currentEquipmentLocation || currentEquipmentLocation.id !== request.location.id) {
        dispatch('updatedEquipmentLocation', { locationID: request.location.id, equipment: request.equipment });
        const updateStatusRequest = { equipmentID: request.equipment.id, status: checkStatuses.CONFIRMED };
        commit('location/updateEquipmentStatus', updateStatusRequest, { root: true });
      }
      const yardCheckId = rootGetters['check/selectedCheck'].id;
      const updatedBy = rootGetters['account/fullName'];
      locationService.updateEquipment(request.location.id, request.equipment, yardCheckId, updatedBy);
    } catch (error) {
      dispatch('alert/raiseError', error.message, { root: true });
    }
    commit('setShowEditEquipmentDialog', false);
  },
  updatedEquipment({ commit }, equipment) {
    commit('updateEquipment', equipment);
  },
  updatedEquipmentLocation({ commit }, request) {
    commit('removeEquipment', request.equipment.id);
    commit('addEquipmentToLocation', request);
  },
  setSelectedLocationToMove({ commit }, location) {
    commit('setSelectedLocationToMove', location);
  },
  setLocationStatus({ commit }, request) {
    commit('setLocationStatus', request);
  },
  updateEquipmentStatus({ commit }, request) {
    commit('updateEquipmentStatus', request);
  },
  updateLocationStatus({ commit }, request) {
    commit('updateLocationStatus', request);
  },
  clearStatuses({ commit }) {
    commit('clearStatuses');
  },
  setShowEditEquipmentDialog({ commit }, value) {
    commit('setShowEditEquipmentDialog', value);
  },
  setStatuses({ commit }, locationInfo) {
    commit('setStatuses', locationInfo);
  },
};

const mutations = {
  setStatuses(state, data) {
    const confirmedEquipmentIds = _.map(data.confirmedEquipment, 'id');
    const confirmedLocationIds = _.map(data.confirmedLocations, 'id');

    for (let i = 0; i < state.locations.length; i += 1) {
      for (let j = 0; j < state.locations[i].equipment.length; j += 1) {
        if (confirmedEquipmentIds.indexOf(state.locations[i].equipment[j].id) >= 0) {
          state.locations[i].equipment[j].status = checkStatuses.CONFIRMED;
        } else {
          state.locations[i].equipment[j].status = '';
        }
      }
      if (confirmedLocationIds.indexOf(state.locations[i].id) >= 0) {
        state.locations[i].status = checkStatuses.CONFIRMED;
      } else {
        state.locations[i].status = '';
      }
    }
  },
  updateEquipment(state, updatedEquipment) {
    const stateLocation = state.locations.find((location) => location.equipment.find((equipment) => equipment.id === updatedEquipment.id));
    if (stateLocation) {
      const equipmentIndex = stateLocation.equipment.findIndex((equipment) => equipment.id === updatedEquipment.id);
      if (equipmentIndex >= 0) {
        Vue.set(stateLocation.equipment, equipmentIndex, updatedEquipment);
      }
    }
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setLocations(state, locations) {
    state.locations = locations;
  },
  setIsLoading(state, value) {
    state.isLoading = value;
  },
  setShowSpotSelectionDialog(state, value) {
    state.showSpotSelectionDialog = value;
  },
  addEquipmentToLocation(state, request) {
    const stateLocation = state.locations.find((w) => w.id === request.locationID);
    if (stateLocation) {
      if (stateLocation.equipment.length > 0) {
        for (let i = 0; i < stateLocation.equipment.length; i += 1) {
          const stateLocationEquipment = stateLocation.equipment.find((w) => w.id === request.equipment.id);
          if (!stateLocationEquipment) {
            stateLocation.equipment.push(request.equipment);
          }
          const stateLocationEquipmentID = stateLocation.equipmentIDs.find((id) => id === request.equipment.id);
          if (!stateLocationEquipmentID) {
            stateLocation.equipmentIDs.push(request.equipment.id);
          }
        }
      } else {
        stateLocation.equipment.push(request.equipment);
        stateLocation.equipmentIDs.push(request.equipment.id);
      }
    }
  },
  updateEquipmentStateForLocation(state, { locationId, equipmentState }) {
    const stateLocation = state.locations.find((w) => w.id === locationId);
    if (stateLocation) {
      stateLocation.equipment.state = equipmentState;
    }
  },
  removeMoveFrom(state, request) {
    const stateLocation = state.locations.find((w) => w.id === request.location.id);
    if (stateLocation && stateLocation.equipment.length > 0) {
      const locationEquipment = stateLocation.equipment.find((equipment) => equipment.id === request.equipment.id);
      if (locationEquipment) {
        locationEquipment.hasPendingMove = false;
      }
    }
  },
  removeMoveTo(state, request) {
    const stateLocation = state.locations.find((w) => w.id === request.location.id);
    if (stateLocation) {
      stateLocation.hasMoveTo = false;
    }
  },
  removeEquipmentFromLocation(state, request) {
    const stateLocation = state.locations.find((w) => w.id === request.locationID);
    if (stateLocation) {
      const stateLocationEquipmentIndex = stateLocation.equipment.findIndex((w) => w.id === request.equipment.id);
      if (stateLocationEquipmentIndex >= 0) {
        Vue.delete(stateLocation.equipment, stateLocationEquipmentIndex);
      }
      const stateLocationEquipmentIDIndex = stateLocation.equipmentIDs.findIndex((id) => id === request.equipment.id);
      if (stateLocationEquipmentIDIndex >= 0) {
        Vue.delete(stateLocation.equipmentIDs, stateLocationEquipmentIDIndex);
      }
    }
  },
  removeEquipment(state, equipmentID) {
    const stateLocation = state.locations.find((location) => location.equipment.find((equipment) => equipment.id === equipmentID));
    if (stateLocation) {
      const equipmentIndex = stateLocation.equipment.findIndex((equipment) => equipment.id === equipmentID);
      if (equipmentIndex >= 0) {
        Vue.delete(stateLocation.equipment, equipmentIndex);
      }
      const stateLocationEquipmentIDIndex = stateLocation.equipmentIDs.findIndex((id) => id === equipmentID);
      if (stateLocationEquipmentIDIndex >= 0) {
        Vue.delete(stateLocation.equipmentIDs, stateLocationEquipmentIDIndex);
      }
    }
  },
  addMoveFrom(state, request) {
    const stateLocation = state.locations.find((w) => w.id === request.location.id);
    if (stateLocation && stateLocation.equipment.length > 0) {
      const locationEquipment = stateLocation.equipment.find((equipment) => equipment.id === request.equipment.id);
      if (locationEquipment) {
        locationEquipment.hasPendingMove = true;
      }
    }
  },
  addMoveTo(state, location) {
    const stateLocation = state.locations.find((loc) => loc.id === location.id);
    if (stateLocation) {
      stateLocation.hasMoveTo = true;
    }
  },
  setGroups(state, groups) {
    state.groups = groups;
  },
  setFilter(state, value) {
    state.filter = value;
  },
  clearStatuses(state) {
    for (let i = 0; i < state.locations.length; i += 1) {
      state.locations[i].status = '';
      for (let j = 0; j < state.locations[i].equipment.length; j += 1) {
        state.locations[i].equipment[j].status = '';
      }
    }
  },
  setShowEditEquipmentDialog(state, value) {
    state.showEditEquipmentDialog = value;
  },
  updateEquipmentStatus(state, request) {
    const stateLocation = state.locations.find((location) => location.equipment.find((equipment) => equipment.id === request.equipmentID));
    if (stateLocation) {
      const equipmentAtLocation = stateLocation.equipment.find((equipment) => equipment.id === request.equipmentID);
      if (equipmentAtLocation) {
        equipmentAtLocation.status = request.status;
      }
    }
  },
  updateLocationStatus(state, request) {
    const stateLocation = state.locations.find((location) => location.id === request.locationID);
    if (stateLocation) {
      stateLocation.status = request.status;
    }
  },
  setSelectedGroup(state, value) {
    state.selectedGroup = value;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
